import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import styles from './DateRangePicker.module.scss'
import { CalendarIcon, HelpModal } from 'components'
import { useClickOutside } from 'hooks'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

const DateRangePicker = ({
  classNames = { container: '', label: '', button: '' },
  label = null,
  captionText = null,
  requiredShown = false,
  optionalShown = false,
  isError = false,
  onChange = () => null,
  disabled = false,
  startDate: initStartDate = null,
  endDate: initEndDate = null,
  activeCampagin = false,
  help = false,
  helpChildren = <></>,
}) => {
  const datePickerRef = useRef(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  useClickOutside(datePickerRef, () => setShowDatePicker(false))

  useEffect(() => {
    if (startDate && endDate)
      onChange({
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
      })
    // eslint-disable-next-line
  }, [startDate, endDate])

  useEffect(() => {
    if (initStartDate !== startDate) setStartDate(new Date(initStartDate))
    if (initEndDate !== endDate) setEndDate(new Date(initEndDate))
    // eslint-disable-next-line
  }, [initStartDate, initEndDate])

  const handleChangeDateRange = (dates) => {
    if (activeCampagin) {
      if (dates < startDate) {
        toast.error('Please select date after start date of campaign')
        return
      }
      setEndDate(dates)
      if (dates) setShowDatePicker(false)
      return
    }
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)

    if (start & end) setShowDatePicker(false)
  }

  return (
    <div className={clsx(classNames.container, styles.date_range)}>
      {label ? (
        <div className={styles.date_range__dis}>
          <label className={clsx(classNames.label, styles.date_range__label)}>
            {label === ' ' ? <>&nbsp;</> : label}
            {requiredShown ? (
              <span className={styles.date_range__required}>&nbsp;*</span>
            ) : null}
            {optionalShown ? (
              <span className={styles.text_input__optional}>
                &nbsp;(optional)
              </span>
            ) : null}
          </label>
          {help && <HelpModal help={help}>{helpChildren}</HelpModal>}
        </div>
      ) : null}

      <button
        type="button"
        className={clsx(
          classNames.button,
          styles.date_range__button,
          isError && styles[`date_range__button--error`]
        )}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault()
          setShowDatePicker(!showDatePicker)
        }}
      >
        <CalendarIcon className={styles.icon__calendar} />

        <span
          className={clsx(activeCampagin && styles.date_range__button__active)}
        >
          {startDate ? new Date(startDate).toLocaleDateString() : null}
        </span>
        <span>
          {startDate || endDate ? <>&nbsp;-&nbsp;</> : null}
          {endDate ? new Date(endDate).toLocaleDateString() : null}
        </span>
      </button>

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>

      {showDatePicker ? (
        <div ref={datePickerRef} className={styles.date_picker}>
          <DatePicker
            selected={startDate}
            onChange={handleChangeDateRange}
            startDate={startDate}
            endDate={endDate}
            {...(activeCampagin
              ? { selectsEnd: true }
              : { selectsRange: true })}
            inline
          />
        </div>
      ) : null}
    </div>
  )
}

export default DateRangePicker
