import React, { useEffect, useState, useRef } from 'react'
import styles from './Verification.module.scss'
import clsx from 'clsx'
import {
  PrimaryButton,
  SecondaryButton,
  CSVIcon,
  PlusCircleIcon,
  ActionIcon,
  AddReferralManuallyModal,
  ConfirmModal,
  TrashIcon,
  DownloadReportButton,
  HelpModal,
} from 'components'
import { useStateValue } from 'state'
import { useClickOutside, useNavigate } from 'hooks'
import {
  AMBASSADOR_STATUS,
  PATH,
  REFERRAL_TYPE,
  REPORT_TYPE,
} from 'utils/constants'
import moment from 'moment'
import { getAxiosInstance } from 'utils'
import { toast } from 'react-toastify'
import AddAmbassadorManually from 'components/Modals/AddAmbassadorManually'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import CTDatePicker from 'components/DatePicker/DatePicker'

const verificationHeaders = [
  { label: 'Campaign Name', key: 'name' },
  { label: 'Ambassador Name', key: 'ambassadorName' },
  { label: 'Ambassador Email', key: 'ambassadorEmail' },
  { label: 'Ambassador Reward Description', key: 'amb_rwd_1_desc' },
  { label: 'Ambassador Reward Amount', key: 'amb_rwd_1_amount' },
  { label: 'Ambassador Reward Type', key: 'amb_rwd_1_type' },
  { label: 'Prospect Name', key: 'prospectName' },
  { label: 'Prospect Email', key: 'prospectEmail' },
  { label: 'Prospect Reward Description', key: 'prospect_rwd_1_desc' },
  { label: 'Prospect Reward Amount', key: 'prospect_rwd_1_amount' },
  { label: 'Prospect Reward Type', key: 'prospect_rwd_1_type' },
  { label: 'P-Learn More', key: 'referralInquiry' },
  { label: 'P-Claim Code', key: 'referralClaimCode' },
  { label: 'Registered', key: 'registered' },
  { label: 'Registration Date', key: 'registration' },
  { label: 'Ambassador Paid', key: 'ambassador_paid' },
  { label: 'Ambassador Paid Value', key: 'ambassador_paid_value' },
  { label: 'Prospect Paid', key: 'prospect_paid' },
  { label: 'Prospect Paid Value', key: 'prospect_paid_value' },
]
const ambassadorHeaders = [
  { label: 'Campaign Name', key: 'name' },
  { label: 'Ambassador First Name', key: 'ambassadorFName' },
  { label: 'Ambassador Last Name', key: 'ambassadorLName' },
  { label: 'Email', key: 'ambassadorEmail' },
  { label: 'Phone', key: 'ambassadorPhone' },
  { label: 'Joined Date', key: 'joinedDate' },
  { label: 'Deleted', key: 'deleted' },
]

const relationshipHeaders = [
  { label: 'Campaign Name', key: 'name' },
  { label: 'Ambassador First Name', key: 'ambassadorFName' },
  { label: 'Ambassador Last Name', key: 'ambassadorLName' },
  { label: 'Ambassador Email', key: 'ambassadorEmail' },
  { label: 'Ambassador Phone', key: 'ambassadorPhone' },
  { label: 'Prospect First Name', key: 'prospectFName' },
  { label: 'Prospect Last Name', key: 'prospectLName' },
  { label: 'Prospect Email', key: 'prospectEmail' },
  { label: 'Prospect Phone', key: 'ProspectPhone' },
]
const Verification = ({
  campaignId,
  onBack = () => null,
  onNext = () => null,
  campaign,
}) => {
  const {
    action: {
      getCampaignRewards,
      getCampaignReferrals,
      updateCampaignReferral,
      deleteCampaignReferral,
      addCampaignAmbassador,
      launchCampaign,
      getAllCampaignAmbassadors,
    },
    state: {
      camp: { activeCamp },
      help,
    },
  } = useStateValue()
  const [showAddReferralManuallyModal, setAddReferralManuallyModal] =
    useState(false)
  const [showAddAmbassadorManually, setShowAddAmbassadorManually] =
    useState(false)
  const [ambassadorReward, setAmbassadorReward] = useState(null)
  const [prospectReward, setProspectReward] = useState(null)
  const [referrals, setRefferals] = useState([])
  const [newAmbassador, setNewAmbassador] = useState(null)
  const [reportBtnDisabled, setRptBtnDisabled] = useState(false)
  const [csvDownloadReady, setCsvDownloadReady] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [deleteReferral, setDeleteReferral] = useState(null)
  const [verificationSummary, setVerificationSummary] = useState([])
  const [ambassadors, setAmbassadors] = useState([])
  const [ambassadorSummary, setAmbassadorSummary] = useState([])
  const [relationshipSummary, setRelationshipSummary] = useState([])
  const [originalReferrals, setoriginalReferrals] = useState([])
  const [nonReferralAmbs, setNonReferralAmbs] = useState([])
  const [reportType, setReportType] = useState(null)
  const navigate = useNavigate()

  const setRefferalsData = (referrals) => {
    setoriginalReferrals(referrals)
    const refs = []
    referrals.map((a) => {
      const ref = a.ambassador
      ref.referrals.map((referral) => {
        if (!referral.deletedAt) {
          referral.Person = ref.person
          referral.deleted = ref.deletedAt ? true : false
          refs.push(referral)
        }
      })
      refs.concat(refs)
    })
    setRefferals(refs)
  }
  useEffect(() => {
    getCampaignRewards(campaignId, ({ ambassadorRewards, prospectRewards }) => {
      if (ambassadorRewards.length) setAmbassadorReward(ambassadorRewards[0])
      if (prospectRewards.length) setProspectReward(prospectRewards[0])
    })
    getCampaignReferrals(campaignId, setRefferalsData)

    if (campaignId) getAllCampaignAmbassadors(campaignId, setAmbassadors)
    // eslint-disable-next-line
  }, [campaignId])

  const filteredAmbassadors = ambassadors.filter(
    (ambassador) => ambassador.status === AMBASSADOR_STATUS.JOINED
  )

  useEffect(() => {
    if (ambassadors.length && originalReferrals.length) {
      const filteredAmbassadors = ambassadors.filter(
        (ambassador) => ambassador.status === AMBASSADOR_STATUS.JOINED
      )
      const AmbassadorsWithReferralsIds = originalReferrals.map(
        (r) => r.ambassador.person.id
      )

      let nonReferralAmbs = filteredAmbassadors.filter(
        (amb) => !AmbassadorsWithReferralsIds.includes(amb.Person.id)
      )
      setNonReferralAmbs(nonReferralAmbs)
    }
  }, [ambassadors, originalReferrals])

  useEffect(() => {
    prepareVerificationCSV((report) => {
      setVerificationSummary(report)
    })

    prepareRelationshipCSV((report) => {
      setRelationshipSummary(report)
    })
  }, [referrals, nonReferralAmbs])

  useEffect(() => {
    prepareAmbassadorCSV((ambassadors) => {
      setAmbassadorSummary(ambassadors)
    })
  }, [ambassadors])

  const updateData = () => {
    getCampaignRewards(campaignId, ({ ambassadorRewards, prospectRewards }) => {
      if (ambassadorRewards.length) setAmbassadorReward(ambassadorRewards[0])
      if (prospectRewards.length) setProspectReward(prospectRewards[0])
    })

    getCampaignReferrals(campaignId, setRefferalsData)
  }

  const handleAddAmbassadorManually = (data) => {
    addCampaignAmbassador(campaignId, data, (ambassador) => {
      if (!ambassador) {
        toast.info(`Ambassador Already Exists`)
      } else {
        setNewAmbassador(ambassador)
        toast.info(`Ambassador Added, Invitation sent`)
        launchCampaign(
          campaignId,
          { peopleIds: [ambassador.personId] },
          () => {}
        )
      }
    })
  }

  const ReferralActionMenu = ({
    referral,
    ambassadorReward,
    prospectReward,
    onUpdate = () => null,
    onDelete = () => null,
  }) => {
    const [isOpened, setOpened] = useState(false)
    const actionMenuRef = useRef(null)
    const [registrations, setRegistrations] = useState(
      referral.registrations || 1
    )

    useClickOutside(actionMenuRef, () => setOpened(false))

    useEffect(
      () => !isOpened && setRegistrations(referral.registrations || 1),
      [isOpened] // eslint-disable-line
    )

    return (
      <>
        <button
          type="button"
          className={styles.button__action}
          onClick={() => setOpened(true)}
        >
          <ActionIcon className={styles.button__action__icon} />
        </button>

        <div
          ref={actionMenuRef}
          className={clsx(
            styles.action_menu,
            isOpened && styles['action_menu--opened']
          )}
        >
          <div className={styles.action_menu__item}>
            <span>Registrations:</span>

            <input
              type="number"
              step="1"
              min="1"
              max="10"
              className={styles.input__registrations}
              value={registrations}
              onChange={({ target: { value } }) => {
                if (value === '') setRegistrations(0)
                else setRegistrations(parseInt(value))
              }}
            />

            <PrimaryButton
              className={styles.button__approve}
              label={`${registrations > 0 ? 'Approve' : 'Reject'}`}
              onClick={() =>
                updateCampaignReferral(
                  campaignId,
                  {
                    id: referral.id,
                    registrations,
                    registeredAt: registrations > 0 ? new Date() : null,
                    ...(registrations === 0
                      ? {
                          prospectPaid: null,
                          prospectPaidValue: null,
                          ambassadorPaid: null,
                          ambassadorPaidValue: null,
                        }
                      : {
                          prospectPaid: referral.prospectPaid
                            ? new Date()
                            : null,
                          prospectPaidValue: referral.prospectPaid
                            ? registrations * prospectReward?.amount
                            : null,
                          ambassadorPaid: referral.ambassadorPaid
                            ? new Date()
                            : null,
                          ambassadorPaidValue: referral.ambassadorPaid
                            ? registrations * ambassadorReward?.amount
                            : null,
                        }),
                  },
                  onUpdate
                )
              }
            />
          </div>
        </div>
      </>
    )
  }

  const AmbassadorPaidMenu = ({ referral, reward, onUpdate = () => null }) => {
    const ambassadorMenuRef = useRef(null)

    const [ambassadorPaidValue, setAmbassadorPaidValue] = useState(
      referral.ambassadorPaidValue
        ? referral.ambassadorPaidValue
        : referral.registrations > 0
        ? reward?.amount * referral.registrations
        : 0
    )
    const [ambassadorPaid, setAmbassadorPaid] = useState(
      referral.ambassadorPaid ? new Date(referral.ambassadorPaid) : null
    )
    const [isOpened, setOpened] = useState(false)

    useClickOutside(ambassadorMenuRef, () => setOpened(false))

    return (
      <>
        <button
          type="button"
          className={styles.button__action}
          onClick={() => setOpened(true)}
        >
          <ActionIcon className={styles.button__action__icon} />
        </button>

        <div
          ref={ambassadorMenuRef}
          className={clsx(
            styles.action_menu_paid,
            isOpened && styles['action_menu_paid--opened']
          )}
        >
          <CTDatePicker date={ambassadorPaid} onChange={setAmbassadorPaid} />
          <input
            className={styles.input__paid}
            type="number"
            value={ambassadorPaidValue}
            placeholder="Amount"
            onChange={(e) => setAmbassadorPaidValue(e.target.value)}
            min={0}
          />
          <PrimaryButton
            className={styles.button__save}
            label="Confirm"
            onClick={() => {
              if (!ambassadorPaidValue)
                toast.error('Enter valid amount to pay the ambassador')
              if (!ambassadorPaid)
                toast.error('Please select the date of payment')
              if (ambassadorPaidValue && ambassadorPaid)
                updateCampaignReferral(
                  campaignId,
                  {
                    id: referral.id,
                    ambassadorPaidValue,
                    ambassadorPaid,
                  },
                  onUpdate
                )
            }}
          />
        </div>
      </>
    )
  }
  const ProspectPaidMenu = ({ referral, reward, onUpdate = () => null }) => {
    const prospectMenuRef = useRef(null)

    const [prospectPaidValue, setProspectPaidValue] = useState(
      referral.prospectPaidValue
        ? referral.prospectPaidValue
        : referral.registrations > 0
        ? reward?.amount * referral.registrations
        : 0
    )
    const [prospectPaid, setProspectPaid] = useState(
      referral.prospectPaid ? new Date(referral.prospectPaid) : null
    )

    const [isOpened, setOpened] = useState(false)

    useClickOutside(prospectMenuRef, () => setOpened(false))
    return (
      <>
        <button
          type="button"
          className={styles.button__action}
          onClick={() => setOpened(true)}
        >
          <ActionIcon className={styles.button__action__icon} />
        </button>
        <div
          ref={prospectMenuRef}
          className={clsx(
            styles.action_menu_paid,
            isOpened && styles['action_menu_paid--opened']
          )}
        >
          <CTDatePicker date={prospectPaid} onChange={setProspectPaid} />
          <input
            className={styles.input__paid}
            type="number"
            value={prospectPaidValue}
            placeholder="Amount"
            onChange={(e) => setProspectPaidValue(e.target.value)}
            min={0}
          />

          <PrimaryButton
            className={styles.button__save}
            label="Confirm"
            onClick={() => {
              if (!prospectPaidValue)
                toast.error('Enter valid amount to pay the prospect')
              if (!prospectPaid)
                toast.error('Please select the date of payment')
              if (prospectPaidValue && prospectPaid)
                updateCampaignReferral(
                  campaignId,
                  {
                    id: referral.id,
                    prospectPaidValue,
                    prospectPaid,
                  },
                  onUpdate
                )
            }}
          />
        </div>
      </>
    )
  }

  const csvReportClick = () => {
    setRptBtnDisabled(true)
    setCsvDownloadReady(true)

    setTimeout(() => {
      setRptBtnDisabled(false)
      setCsvDownloadReady(false)
    }, 100)
  }

  const getFullName = ({ firstName, lastName }) => `${firstName} ${lastName}`
  const getInquiry = ({ history }) => {
    let inquiry = _.find(history, (h) => h.type === REFERRAL_TYPE.INQUIRY)
    if (inquiry)
      return (
        <div className={styles.section__referral}>
          <span className={styles.section__referral_date}>
            LM- {moment(inquiry.created_at).format('MM/DD/YYYY')}
          </span>
        </div>
      )
    else return
  }
  const getInquiryCSV = ({ history }) => {
    let inquiry = _.find(history, (h) => h.type === REFERRAL_TYPE.INQUIRY)
    if (inquiry) return moment(inquiry.created_at).format('MM/DD/YYYY')
    else return
  }

  const prepareVerificationCSV = (callback) => {
    let records = []
    referrals.concat(nonReferralAmbs).map((referral) => {
      let temp = {}
      temp['name'] = campaign?.name
      temp['ambassadorEmail'] = referral?.Person?.email

      temp['ambassadorName'] = referral?.Person
        ? getFullName(referral.Person)
        : null
      temp['prospectEmail'] = referral.uniqueLink ? null : referral?.email
      temp['prospectName'] = referral.uniqueLink ? null : getFullName(referral)
      temp['referralInquiry'] = referral.uniqueLink
        ? null
        : referral?.type === REFERRAL_TYPE.INQUIRY
        ? moment(referral?.updated_at).format('MM/DD/YYYY')
        : getInquiryCSV(referral)
      temp['referralClaimCode'] = referral.uniqueLink
        ? null
        : referral?.type === REFERRAL_TYPE.REGISTRATION
        ? moment(referral?.updated_at).format('MM/DD/YYYY')
        : null
      temp['registered'] =
        !referral?.uniqueLink && referral?.registeredAt
          ? referral.registrations
          : null
      temp['registration'] =
        !referral.uniqueLink && referral.registeredAt
          ? moment(referral.registeredAt).format('MM/DD/YYYY')
          : null
      temp['ambassador_paid'] =
        !referral.uniqueLink && referral.ambassadorPaid
          ? moment(referral.ambassadorPaid).format('MM/DD/YYYY')
          : null
      temp['ambassador_paid_value'] =
        !referral.uniqueLink && referral.ambassadorPaidValue
          ? referral.ambassadorPaidValue
          : null
      temp['prospect_paid'] =
        !referral.uniqueLink && referral.prospectPaid
          ? moment(referral.prospectPaid).format('MM/DD/YYYY')
          : null
      temp['prospect_paid_value'] =
        !referral.uniqueLink && referral.prospectPaidValue
          ? referral.prospectPaidValue
          : null

      records.push(temp)
    })

    const report = records.map((r) => {
      r[`amb_rwd_1_desc`] = ambassadorReward?.desc
      r[`amb_rwd_1_type`] = ambassadorReward?.type
      r[`amb_rwd_1_amount`] = ambassadorReward?.amount
      r[`prospect_rwd_1_desc`] = prospectReward?.desc
      r[`prospect_rwd_1_type`] = prospectReward?.type
      r[`prospect_rwd_1_amount`] = prospectReward?.amount
      r.date = new Date(r.date).toLocaleDateString()
      return r
    })

    callback(report)
  }
  const prepareAmbassadorCSV = (callback) => {
    let records = []

    filteredAmbassadors.map((ambassador) => {
      let temp = {}
      temp['name'] = campaign?.name
      temp['ambassadorEmail'] = ambassador?.Person?.email

      temp['ambassadorFName'] = ambassador?.Person
        ? ambassador.Person.firstName
        : null
      temp['ambassadorLName'] = ambassador?.Person
        ? ambassador.Person.lastName
        : null
      temp['ambassadorPhone'] = ambassador?.Person?.phone
      temp['joinedDate'] = ambassador.enrollDate
        ? moment(ambassador.enrollDate).format('MM/DD/YYYY')
        : '-'
      temp['deleted'] = ambassador.deleted_at !== null ? 'Yes' : 'No'
      records.push(temp)
    })

    callback(records)
  }

  const prepareRelationshipCSV = (callback) => {
    let records = []
    referrals.concat(nonReferralAmbs).map((referral) => {
      let temp = {}
      temp['name'] = campaign?.name
      temp['ambassadorEmail'] = referral?.Person?.email

      temp['ambassadorFName'] = referral?.Person
        ? referral.Person.firstName
        : null
      temp['ambassadorLName'] = referral?.Person
        ? referral.Person.lastName
        : null
      temp['ambassadorPhone'] = referral?.Person?.phone

      temp['prospectEmail'] = referral.uniqueLink ? null : referral?.email
      temp['prospectFName'] = referral.uniqueLink ? null : referral.firstName
      temp['prospectLName'] = referral.uniqueLink ? null : referral.lastName
      temp['ProspectPhone'] = referral.uniqueLink ? null : referral.phone

      records.push(temp)
    })

    callback(records)
  }

  const getReferral = (referral) => {
    return (
      <>
        {referral.type === REFERRAL_TYPE.INQUIRY ? (
          <>LM- {moment(referral.updated_at).format('MM/DD/YYYY')}</>
        ) : (
          getInquiry(referral)
        )}

        {referral.type === REFERRAL_TYPE.REGISTRATION ? (
          <>CC- {moment(referral.updated_at).format('MM/DD/YYYY')} </>
        ) : (
          ''
        )}
      </>
    )
  }

  const columns = [
    {
      name: (
        <div
          key={'Ambassador'}
          className={clsx(
            styles.metrics__th,
            'Ambassador Name' &&
              styles[
                `metrics__th__${'Ambassador Name'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Ambassador
        </div>
      ),
      selector: (referral) => (
        <Link
          to={`${PATH.AMBASSADORS__EDIT}/${referral?.Person?.id}`}
          style={{ color: '#000' }}
        >
          {referral?.Person ? getFullName(referral.Person) : null}
        </Link>
      ),
      sortable: true,
      width: '15%',
    },

    {
      name: (
        <div
          key={'Prospect'}
          className={clsx(
            styles.metrics__th,
            'Prospect' &&
              styles[
                `metrics__th__${'Prospect'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Prospect
        </div>
      ),
      selector: (referral) => (
        <Link
          to={`${PATH.PROSPECTS__EDIT}/${referral.id}`}
          style={{ color: '#000' }}
        >
          {referral ? getFullName(referral) : null}
        </Link>
      ),
      sortable: true,
      width: '15%',
    },

    {
      name: (
        <div
          key={'Referral'}
          className={clsx(
            styles.metrics__th,
            'Referral' &&
              styles[
                `metrics__th__${'Referral Date'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Referral
          {help.find((h) => h.tag === 'verification_referral') && (
            <HelpModal
              className={styles.helpModal__header}
              help={help.find((h) => h.tag === 'verification_referral')}
            >
              <p>{help.find((h) => h.tag === 'verification_referral')?.text}</p>
            </HelpModal>
          )}
        </div>
      ),
      selector: (referral) => getReferral(referral),
      width: '15%',
    },

    {
      name: (
        <div
          key={'Approve Registration'}
          className={clsx(
            styles.metrics__th,
            'Approve Registration' &&
              styles[
                `metrics__th__${'Approve Registration'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Registration
          {help.find((h) => h.tag === 'verification_registration') && (
            <HelpModal
              className={styles.helpModal__header}
              help={help.find((h) => h.tag === 'verification_registration')}
            >
              <p>
                {help.find((h) => h.tag === 'verification_registration')?.text}
              </p>
            </HelpModal>
          )}
        </div>
      ),
      selector: (referral) =>
        referral.registeredAt ? (
          <div className={styles.referral__registration}>
            {moment(referral?.registeredAt).format('MM/DD/YYYY') + ' '}
            {referral.registrations ? (
              <p style={{ marginTop: '10px' }}>{referral.registrations}</p>
            ) : null}
          </div>
        ) : (
          ''
        ),
      width: '15%',
      style: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
      },
    },

    {
      name: (
        <div
          className={clsx(styles.metrics__th, styles.metrics__th__action)}
        ></div>
      ),
      selector: (referral) => (
        <div className={styles.referral__action}>
          <ReferralActionMenu
            referral={referral}
            ambassadorReward={ambassadorReward}
            prospectReward={prospectReward}
            onUpdate={(data) => {
              setRefferals(
                referrals.map((referral) => ({
                  ...referral,
                  ...(referral.id === data.id ? data : {}),
                }))
              )
              if (data.registeredAt)
                toast.success('Fantastic! You have a new registration!')
            }}
            onDelete={() =>
              setRefferals(referrals.filter(({ id }) => id !== referral.id))
            }
          />
        </div>
      ),
      allowOverflow: true,
      width: '5%',
      style: {
        borderRight: '1px solid rgba(0,0,0,.12)',
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },

    {
      name: (
        <div
          key={'Referral'}
          className={clsx(
            styles.metrics__th,
            'Referral' &&
              styles[
                `metrics__th__${'Referral Date'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          A-Paid
          {help.find((h) => h.tag === 'verification_a_paid') && (
            <HelpModal
              className={styles.helpModal__header}
              help={help.find((h) => h.tag === 'verification_a_paid')}
            >
              <p>{help.find((h) => h.tag === 'verification_a_paid')?.text}</p>
            </HelpModal>
          )}
        </div>
      ),
      allowOverflow: true,
      selector: (referral) =>
        referral.registeredAt ? (
          <div style={{ display: 'flex' }}>
            {referral.ambassadorPaid ? (
              <div
                className={styles.referral__registration}
                style={{ marginRight: '5px' }}
              >
                {moment(referral?.ambassadorPaid).format('MM/DD/YYYY') + ' '}
                {referral.registrations && referral.ambassadorPaidValue ? (
                  <p style={{ marginTop: '10px' }}>
                    {activeCamp?.currency + referral.ambassadorPaidValue}
                  </p>
                ) : null}
              </div>
            ) : null}

            <AmbassadorPaidMenu
              referral={referral}
              reward={ambassadorReward}
              onUpdate={(data) => {
                setRefferals(
                  referrals.map((referral) => ({
                    ...referral,
                    ...(referral.id === data.id ? data : {}),
                  }))
                )
                if (data?.ambassadorPaid)
                  toast.success('Ambassador amount paid')
              }}
            />
          </div>
        ) : (
          ''
        ),
      width: '15%',
      style: {
        paddingRight: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
      },
    },

    {
      name: (
        <div
          key={'Referral'}
          className={clsx(
            styles.metrics__th,
            'Referral' &&
              styles[
                `metrics__th__${'Referral Date'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          P-Paid
          {help.find((h) => h.tag === 'verification_p_paid') && (
            <HelpModal
              className={styles.helpModal__header}
              help={help.find((h) => h.tag === 'verification_p_paid')}
            >
              <p>{help.find((h) => h.tag === 'verification_p_paid')?.text}</p>
            </HelpModal>
          )}
        </div>
      ),
      allowOverflow: true,
      selector: (referral) =>
        referral.registeredAt ? (
          <div style={{ display: 'flex' }}>
            {referral.prospectPaid ? (
              <div
                className={styles.referral__registration}
                style={{ marginRight: '5px' }}
              >
                {moment(referral?.prospectPaid).format('MM/DD/YYYY') + ' '}
                {referral.registrations && referral.prospectPaidValue ? (
                  <p style={{ marginTop: '10px' }}>
                    {activeCamp?.currency + referral.prospectPaidValue}
                  </p>
                ) : null}
              </div>
            ) : null}
            <ProspectPaidMenu
              referral={referral}
              reward={prospectReward}
              onUpdate={(data) => {
                setRefferals(
                  referrals.map((referral) => ({
                    ...referral,
                    ...(referral.id === data.id ? data : {}),
                  }))
                )
                if (data?.registeredAt) toast.success('Prospect Amount Paid')
              }}
            />
          </div>
        ) : (
          ''
        ),
      width: '15%',
      style: {
        paddingRight: '0px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
      },
    },

    {
      name: (
        <div
          key={'Approve Registration'}
          className={clsx(
            styles.metrics__th,
            'Approve Registration' &&
              styles[
                `metrics__th__${'Approve Registration'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        ></div>
      ),
      selector: (referral) => (
        <button
          onClick={() => {
            setDeleteReferral(referral.id)
            setConfirmDelete(true)
          }}
        >
          <TrashIcon className={styles['button__icon--disabled']} />
        </button>
      ),
      width: '5%',
      style: {
        paddingLeft: '0px',
        paddingRight: '0px',
        textAlign: 'center',
      },
    },
  ]

  const ambassadorColumns = [
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Name
        </div>
      ),
      selector: (ambassador) =>
        ambassador.deleted_at == null ? (
          <Link
            to={`${PATH.AMBASSADORS__EDIT}/${ambassador?.Person?.id}`}
            style={{ color: '#000' }}
          >
            {ambassador?.Person ? getFullName(ambassador.Person) : null}
          </Link>
        ) : ambassador?.Person ? (
          getFullName(ambassador.Person)
        ) : null,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Email
        </div>
      ),
      selector: (ambassador) => ambassador.Person.email,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__status
          )}
        >
          Join Date
        </div>
      ),
      selector: (ambassador) =>
        ambassador.enrollDate
          ? moment(ambassador.enrollDate).format('MM/DD/YYYY')
          : '-',
      sortable: true,
    },
  ]

  const conditionalRowStyles = [
    {
      when: (row) => row.deleted_at !== null,
      style: {
        backgroundColor: '#ededed',
        color: 'black',
        cursor: 'not-allowed',
        '&:hover': {
          cursor: 'not-allowed !important',
        },
      },
    },
  ]

  return (
    <>
      <div className={styles.section}>
        <div className={styles.profile_container}>
          <h4 className={styles.section__title}>
            Verification <br />
          </h4>
          {help.find((h) => h.tag === 'verification_overview') && (
            <HelpModal
              className={styles.helpModal__top}
              help={help.find((h) => h.tag === 'verification_overview')}
            >
              <p>{help.find((h) => h.tag === 'verification_overview')?.text}</p>
            </HelpModal>
          )}
        </div>
        <div className={styles.section__header}>
          {[
            {
              reward: ambassadorReward,
              type: 'ambassador',
              helpTag: 'verification_ambassador_reward',
            },
            {
              reward: prospectReward,
              type: 'prospect',
              helpTag: 'verification_prospect_reward',
            },
          ].map(
            ({ reward, type, helpTag }, rIdx) =>
              reward && (
                <div key={rIdx} className={styles.reward}>
                  <div className={styles.reward__img}>
                    {reward.imgURL && (
                      <img src={reward.imgURL} alt={reward.desc} />
                    )}
                  </div>
                  <div className={styles.reward__texts}>
                    <span
                      className={clsx(
                        styles.reward__for,
                        styles[`reward__for--${type}`]
                      )}
                    >
                      {rIdx === 0 ? 'Amabassador' : 'Prospect'} Reward
                    </span>

                    <span className={styles.reward__price}>{reward.desc}</span>
                  </div>
                  {help.find((h) => h.tag === helpTag) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find((h) => h.tag === helpTag)}
                    >
                      <p>{help.find((h) => h.tag === helpTag)?.text}</p>
                    </HelpModal>
                  )}
                </div>
              )
          )}
        </div>

        <div className={styles.section__main}>
          <div className={styles.metrics__header}>
            <div className={styles.metrics__title}>
              Campaign Metrics
              {help.find((h) => h.tag === 'verification_campaign_metrics') && (
                <HelpModal
                  className={styles.helpModal__metrics}
                  help={help.find(
                    (h) => h.tag === 'verification_campaign_metrics'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'verification_campaign_metrics'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>

            <SecondaryButton className={styles.button__upload}>
              <CSVIcon className={styles.button__icon} />
              Upload CSV
            </SecondaryButton>

            <DownloadReportButton
              reportBtnDisabled={reportBtnDisabled}
              reports={[
                REPORT_TYPE.VERIFICATION,
                REPORT_TYPE.AMBASSADOR,
                REPORT_TYPE.RELATIONSHIP,
              ]}
              clickDownloadReport={(type) => {
                setReportType(type)
                csvReportClick()
              }}
              csvDownloadReady={csvDownloadReady}
              headers={
                reportType === REPORT_TYPE.AMBASSADOR
                  ? ambassadorHeaders
                  : reportType === REPORT_TYPE.VERIFICATION
                  ? verificationHeaders
                  : relationshipHeaders
              }
              data={
                reportType === REPORT_TYPE.AMBASSADOR
                  ? ambassadorSummary
                  : reportType === REPORT_TYPE.VERIFICATION
                  ? verificationSummary
                  : relationshipSummary
              }
              reportType={reportType}
            />
            {help.find((h) => h.tag === 'verification_download_report') && (
              <HelpModal
                className={styles.helpModal__report}
                help={help.find(
                  (h) => h.tag === 'verification_download_report'
                )}
              >
                <p>
                  {
                    help.find((h) => h.tag === 'verification_download_report')
                      ?.text
                  }
                </p>
              </HelpModal>
            )}
            <PrimaryButton
              className={styles.button}
              onClick={() => {
                setAddReferralManuallyModal(true)
              }}
            >
              <PlusCircleIcon className={styles.button__icon} />
              Manual Entry
            </PrimaryButton>
            {help.find((h) => h.tag === 'verification_manual_entry') && (
              <HelpModal
                className={styles.helpModal__metrics}
                help={help.find((h) => h.tag === 'verification_manual_entry')}
              >
                <p>
                  {
                    help.find((h) => h.tag === 'verification_manual_entry')
                      ?.text
                  }
                </p>
              </HelpModal>
            )}
          </div>

          <DataTable
            columns={columns}
            data={referrals}
            pagination
            customStyles={{
              cells: {
                style: {
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  alignItems: 'baseline',
                  textAlign: 'center',
                },
              },
              table: {
                style: {
                  overflow: 'auto',
                },
              },
            }}
          />
          <div className={styles.metrics__title_2}>
            Joined Ambassadors
            {help.find((h) => h.tag === 'verification_joined_ambassadors') && (
              <HelpModal
                className={styles.helpModal__header}
                help={help.find(
                  (h) => h.tag === 'verification_joined_ambassadors'
                )}
              >
                <p>
                  {
                    help.find(
                      (h) => h.tag === 'verification_joined_ambassadors'
                    )?.text
                  }
                </p>
              </HelpModal>
            )}
          </div>

          <DataTable
            columns={ambassadorColumns}
            data={filteredAmbassadors}
            conditionalRowStyles={conditionalRowStyles}
            pagination
          />
        </div>

        <div className={styles.section__footer}>
          <SecondaryButton
            className={styles.button__back}
            label="Back"
            onClick={onBack}
          />

          <PrimaryButton
            className={styles.button__next}
            label="Next"
            onClick={onNext}
          />

          <SecondaryButton
            label="Close"
            onClick={() => navigate({ pathname: PATH.CAMPAIGNS })}
          />
        </div>
      </div>
      <AddReferralManuallyModal
        show={showAddReferralManuallyModal}
        onClose={() => {
          setAddReferralManuallyModal(false)
          setNewAmbassador(null)
        }}
        onAdd={(args) => {
          getAxiosInstance()
            .post('/api/share/manual/referral', {
              ...args,
              campaignId,
              type: REFERRAL_TYPE.REGISTRATION,
            })
            .then(({ data }) => {
              if (!data.success) toast.error(data.message)
              updateData()
            })
        }}
        addAmbassador={() => {
          setShowAddAmbassadorManually(true)
        }}
        ambassador={newAmbassador}
      />

      <AddAmbassadorManually
        classNames={{ modal: styles.modal__add_ambassador_manually }}
        data={{}}
        show={showAddAmbassadorManually}
        onClose={() => setShowAddAmbassadorManually(false)}
        onAdd={handleAddAmbassadorManually}
        description="An invitation email will be sent to this Ambassador to accept the terms and conditions."
      />

      <ConfirmModal
        show={confirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this Relationship?"
        onCancel={() => {
          setConfirmDelete(false)
          setDeleteReferral(null)
        }}
        okLabel="Delete"
        onOK={() => {
          if (deleteReferral)
            deleteCampaignReferral(campaignId, deleteReferral, () => {
              setRefferals(referrals.filter(({ id }) => id !== deleteReferral))
            })
          setConfirmDelete(false)
        }}
      />
    </>
  )
}

export default Verification
