import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { useFormik } from 'formik'
import styles from './AmbassadorSetup.module.scss'
import {
  ImagePlaceholderFillIcon,
  ImagePlaceholderIcon,
  PencilIcon,
  EyeVisibleColorIcon,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  TextArea,
  TextInput,
  Checkbox,
  HtmlEditor,
  AccordionSection,
  EmailTemplateEditorModal,
  LoadingOverlay,
  HelpModal,
} from 'components'
import * as Yup from 'yup'
import { CAMPAIGN_STEP, ERR_CODE, MESSAGE, PATH } from 'utils/constants'
import { getFormikCaptionTextData, oneMbSizeLimit } from 'utils'
import { useStateValue } from 'state'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { EmailTemplateSection } from 'sections/Campaigns/AmbassadorSetup'

const Handlebars = require('handlebars')

const AmbassadorSetup = ({
  isLocked = false,
  step: initStep,
  onBack = () => null,
  onNext = () => null,
  onSave = () => null,
  data: initData = {},
  campaignId = null,
  campaignSlug = '',
  setDirty = () => null,
  saveAndClose = () => null,
}) => {
  const publicLink = `${process.env.REACT_APP_SHARE_HOST}/a/c/${campaignSlug}`
  const invitationsRef = useRef(null)
  const dashboardRef = useRef(null)
  const emailsRef = useRef(null)
  const [sectionOpened, setSectionOpened] = useState('landing-page')
  const termsRef = useRef(null)
  const landingBgFileRef = useRef(null)
  const {
    action: {
      updateAmbassadorSetup,
      popupError,
      getTermsTemplate,
      updateTermsTemplate,
      getEmailTemplate,
      updateEmailTemplate,
    },
    state: { help },
  } = useStateValue()
  const [isSubmitted, setSubmitted] = useState(false)
  const [isNext, setNext] = useState(false)
  const [isStayHere, setIsStayHere] = useState(false)
  const [isEmailEditorOpened, setEmailEditorOpened] = useState(false)
  const [emailTemplateToEdit, setEmailTemplateToEdit] = useState({})
  const [optionalTags, setOptionalTags] = useState([])
  const [requiredTags, setRequiredTags] = useState([])
  const [templateVars, setTemplateVars] = useState([])
  const [isLandingPagePreview, setLandingPagePreview] = useState(false)
  const [isEmailPreview, setEmailPreview] = useState(false)
  const [isDashboardPagePreview, setDashboardPagePreview] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [originTemplate, setOriginTemplate] = useState(undefined)
  const [isTemplateDirty, setIsTemplateDirty] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    setDirty(isDirty || isTemplateDirty)
  }, [isDirty, isTemplateDirty])

  const showPopup = (url) => {
    let opened = window.open(url, '_blank')
    if (opened) {
      opened.focus()
      return
    }

    popupError({
      message: JSON.stringify({
        code: ERR_CODE.POPUP_BLOCKED,
        message:
          'Please allow popups. This preview will not work without popups.',
      }),
    })
  }

  const makeFullTag = (tag) => {
    try {
      // tags that end in '-image' or '-link' need 3 curly brackets
      const type = tag.split('-')?.pop()
      if (type === 'image' || type === 'link') {
        return `{{{${tag}}}}`
      } else {
        return `{{${tag}}}`
      }
    } catch (error) {
      return false
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      landingBgImgURL: null,
      landingTitle: '',
      landingDesc: '',
      landingButtonText: '',
      emailSubject: '',
      emailHeading: '',
      emailSubheading: '',
      emailContent: '',
      dashboardHeading: '',
      dashboardSubheading: '',
      terms: '',
      ...initData,
    },
    validationSchema: Yup.object({
      landingTitle: Yup.string()
        .required('Landing page title is required.')
        .max(70, 'Max length is 70.'),
      landingDesc: Yup.string().max(200, 'Max length is 200.'),
      landingButtonText: Yup.string()
        .required('Landing page button text is required.')
        .max(20, 'Max length is 20.'),
      landingBgImgURL: Yup.mixed().required(
        'Landing page background image is required.'
      ),
      emailSubject: Yup.string().required('Email subject is required.'),
      emailHeading: Yup.string().required('Email heading is required.'),
      emailSubheading: Yup.string().required('Email subheading is required.'),
      emailContent: Yup.string()
        .required('Email content is required.')
        .test(
          'required-merge-tags',
          'missing a required merge tag',
          (value, _) => {
            if (value) {
              let result = true
              requiredTags.forEach((tag) => {
                if (value.indexOf(makeFullTag(tag)) === -1) result = false
              })
              return result
            }
          }
        )
        .test(
          'invalid-tags',
          'One of the merge tags is invalid',
          (value, _) => {
            if (value) {
              let template = Handlebars.compile(value)
              try {
                template({})
                return true
              } catch (e) {
                return false
              }
            }
          }
        ),
      dashboardHeading: Yup.string()
        .required('Dashboard Heading is required.')
        .max(65, 'Max length is 65.'),
      dashboardSubheading: Yup.string()
        .required('Dashboard Subheading is required.')
        .max(65, 'Max length is 65.'),
      terms: Yup.string().required('Terms of Use text is required.'),
    }),
    onSubmit: (args) => {
      // if (isLocked)
      //   return isNext ? onNext() : navigate({ pathname: PATH.CAMPAIGNS })
      setLoading(true)

      const data = { ...args, campaignId }
      const handleUpdateTemplates = (ambassadorSetup) =>
        Promise.all([
          isTemplateDirty
            ? updateEmailTemplate({
                campaignId,
                messageType: MESSAGE.A__INVITATION.TYPE,
                templateType: 'content',
                fileType: 'html',
                data: {
                  subject: args.emailSubject,
                  heading: args.emailHeading,
                  subheading: args.emailSubheading,
                  text: args.emailContent,
                },
              })
            : '',
          termsRef.current?.isDirty()
            ? updateTermsTemplate({
                campaignId,
                templateType: 'content',
                fileType: 'html',
                data: { text: args.terms },
              })
            : '',
        ]).then(() => {
          setLoading(false)

          if (isNext) onNext(ambassadorSetup)
          else if (isStayHere) {
            onSave(ambassadorSetup)
          } else saveAndClose()
        })
      if (isDirty) updateAmbassadorSetup(data, handleUpdateTemplates)
      else handleUpdateTemplates(data)
    },
  })

  useEffect(() => {
    setIsDirty(
      !_.isEqual(
        _.omit(formik.values, [
          'emailContent',
          'emailHeading',
          'emailSubheading',
          'emailSubject',
          'terms',
          'created_at',
          'id',
          'templateVars',
          'updated_at',
        ]),
        _.omit(initData, [
          'emailContent',
          'emailHeading',
          'emailSubheading',
          'emailSubject',
          'terms',
          'created_at',
          'id',
          'templateVars',
          'updated_at',
        ])
      )
    )
    const { emailSubject, emailHeading, emailSubheading, emailContent } =
      formik.values
    if (originTemplate)
      setIsTemplateDirty(
        !_.isEqual(
          {
            emailSubject: originTemplate?.subject,
            emailHeading: originTemplate?.heading,
            emailSubheading: originTemplate?.subheading,
            emailContent: originTemplate?.text,
          },
          {
            emailSubject,
            emailHeading,
            emailSubheading,
            emailContent,
          }
        )
      )
  }, [formik, originTemplate, initData])

  // scroll to section
  useEffect(() => {
    if (initStep === CAMPAIGN_STEP.AMBASSADOR_SETUP__DASHBOARD)
      dashboardRef.current.scrollIntoView({ behavior: 'smooth' })
    else if (initStep === CAMPAIGN_STEP.AMBASSADOR_SETUP__EMAILS)
      emailsRef.current.scrollIntoView({ behavior: 'smooth' })
    else window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [initStep])

  // fetch the email and terms templates
  useEffect(() => {
    getEmailTemplate(
      campaignId,
      MESSAGE.A__INVITATION.TYPE,
      ({ htmlContentTemplate, templateVars }) => {
        setOriginTemplate(htmlContentTemplate)
        formik.setFieldValue('emailSubject', htmlContentTemplate.subject)
        formik.setFieldValue('emailHeading', htmlContentTemplate.heading)
        formik.setFieldValue('emailSubheading', htmlContentTemplate.subheading)
        formik.setFieldValue('emailContent', htmlContentTemplate.text)

        setOptionalTags(htmlContentTemplate.optionalTags)
        setRequiredTags(htmlContentTemplate.requiredTags)
        setTemplateVars(templateVars)
      }
    )

    getTermsTemplate(campaignId, ({ htmlContentTemplate }) =>
      formik.setFieldValue('terms', htmlContentTemplate.text)
    )
    // eslint-disable-next-line
  }, [campaignId, initData])

  // handle email preview
  useEffect(() => {
    if (isEmailPreview) {
      if (
        (formik.touched.emailHeading && formik.errors.emailHeading) ||
        !formik.values.emailHeading
      ) {
        setEmailPreview(false)
        return
      }
      if (
        (formik.touched.emailSubheading && formik.errors.emailSubheading) ||
        !formik.values.emailSubheading
      ) {
        setEmailPreview(false)
        return
      }
      if (
        (formik.touched.emailContent && formik.errors.emailContent) ||
        !formik.values.emailContent
      ) {
        setEmailPreview(false)
        return
      }

      updateEmailTemplate(
        {
          campaignId,
          messageType: MESSAGE.A__INVITATION.TYPE,
          templateType: 'content',
          fileType: 'html',
          data: {
            subject: formik.values.emailSubject,
            heading: formik.values.emailHeading,
            subheading: formik.values.emailSubheading,
            text: formik.values.emailContent,
          },
        },
        () => {
          setEmailPreview(false)
          let url = `${PATH.PREVIEW__AS__EMAIL}?campaignId=${campaignId}&messageType=${MESSAGE.A__INVITATION.TYPE}`
          showPopup(url)
        }
      )
    }

    if (isLandingPagePreview) {
      const { landingBgImgURL, landingTitle, landingDesc, landingButtonText } =
        formik.values

      if (
        (formik.touched.landingBgImgURL && formik.errors.landingBgImgURL) ||
        !landingBgImgURL
      ) {
        setLandingPagePreview(false)
        return
      }

      if (
        (formik.touched.landingTitle && formik.errors.landingTitle) ||
        !landingTitle
      ) {
        setLandingPagePreview(false)
        return
      }
      if (
        (formik.touched.landingDesc && formik.errors.landingDesc) ||
        !landingDesc
      ) {
        setLandingPagePreview(false)
        return
      }
      if (
        (formik.touched.landingButtonText && formik.errors.landingButtonText) ||
        !landingButtonText
      ) {
        setLandingPagePreview(false)
        return
      }

      updateAmbassadorSetup(
        {
          id: formik.values.id,
          campaignId,
          landingBgImgURL,
          landingTitle,
          landingDesc,
          landingButtonText,
        },
        () => {
          setLandingPagePreview(false)
          showPopup(publicLink)
        }
      )
    }

    if (isDashboardPagePreview) {
      const { dashboardHeading, dashboardSubheading, terms } = formik.values

      if (
        (formik.touched.dashboardHeading && formik.errors.dashboardHeading) ||
        !dashboardHeading
      )
        return
      if (
        (formik.touched.dashboardSubheading &&
          formik.errors.dashboardSubheading) ||
        !dashboardSubheading
      )
        return
      if ((formik.touched.terms && formik.errors.terms) || !terms) return

      Promise.all([
        updateAmbassadorSetup({
          id: formik.values.id,
          campaignId,
          dashboardHeading,
          dashboardSubheading,
          terms,
        }),
        updateTermsTemplate({
          campaignId,
          templateType: 'content',
          fileType: 'html',
          data: { text: terms },
        }),
      ]).then(() => {
        setDashboardPagePreview(false)

        window
          .open(
            `${process.env.REACT_APP_SHARE_HOST}/a/d/${campaignSlug}?preview=true`,
            '_blank'
          )
          .focus()
      })
    }
    // eslint-disable-next-line
  }, [isEmailPreview, isLandingPagePreview, isDashboardPagePreview])

  // Handle submit
  useEffect(() => {
    if (!isSubmitted) return

    if (_.isEmpty(formik.touched) || _.isEmpty(formik.errors)) return

    const toastErrors = (errors) =>
      _.values(errors).forEach((error) => {
        if (typeof error === 'string') toast.error(error)
        else toastErrors(error)
      })

    toastErrors(formik.errors)

    setSubmitted(false)
    // eslint-disable-next-line
  }, [isSubmitted])

  const handleClickSubmit = (isNext) => {
    setNext(isNext)
    setIsStayHere(false)
    setSubmitted(true)
    formik.handleSubmit()
  }

  const handleSubmitOnly = () => {
    setIsStayHere(true)
    setSubmitted(true)
    formik.handleSubmit()
  }

  const handleLandingBgFileUpload = ({ target: { files } }) => {
    if (!files[0]) return

    if (oneMbSizeLimit(files[0])) {
      formik.setFieldValue('landingBgImgURL', files[0])
    }
  }

  const handleClickLandingPagePreview = () => {
    formik.setFieldTouched('landingBgImgURL', true)
    formik.setFieldTouched('landingTitle', true)
    formik.setFieldTouched('landingDesc', true)
    formik.setFieldTouched('landingButtonText', true)

    setLandingPagePreview(true)
  }

  const handleClickDashboardPagePreview = () => {
    formik.setFieldTouched('dashboardHeading', true)
    formik.setFieldTouched('dashboardSubheading', true)
    formik.setFieldTouched('terms', true)

    setDashboardPagePreview(true)
  }

  const handleClickEmailEditor = (template) =>
    getEmailTemplate(
      campaignId,
      template.TYPE,
      ({ htmlContentTemplate, templateVars }) => {
        setEmailTemplateToEdit({
          title: template.LABEL,
          type: template.TYPE,
          data: {
            subject: htmlContentTemplate.subject,
            heading: htmlContentTemplate.heading,
            subheading: htmlContentTemplate.subheading,
            content: htmlContentTemplate.text,
          },
          optionalTags: htmlContentTemplate.optionalTags,
          requiredTags: htmlContentTemplate.requiredTags,
          templateVars,
        })
        setEmailEditorOpened(true)
      }
    )

  const handleClickEmailPreview = () => {
    formik.setFieldTouched('emailHeading', true)
    formik.setFieldTouched('emailSubheading', true)
    formik.setFieldTouched('emailContent', true)
    setEmailPreview(true)
  }

  const handleClickCopyPublicLink = () => {
    navigator.clipboard.writeText(publicLink)
    toast.info(`Public Link is copied.`)
  }

  const handleClickCopyWidgetCode = () => {
    const html = `<iframe src=${publicLink} width="100%" height="100%" frameBorder="0"></iframe>`
    navigator.clipboard.writeText(html)
    toast.info(`Code is copied.`)
  }

  return (
    <>
      <div className={styles.section}>
        <form className={styles.form}>
          <fieldset
            ref={invitationsRef}
            className={styles.section__invitations}
          >
            <div className={styles.profile_container}>
              <h4 className={styles.section__title}>Invitations</h4>
              {help.find((h) => h.tag === 'ambassador_setup_overview') && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find((h) => h.tag === 'ambassador_setup_overview')}
                >
                  <p>
                    {
                      help.find((h) => h.tag === 'ambassador_setup_overview')
                        ?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            <AccordionSection
              className={styles.accordion_section}
              title="Landing Page"
              description="Invite Ambassador via Landing Page on Camp website"
              opened={sectionOpened === 'landing-page'}
              onActionToOpen={() => setSectionOpened('landing-page')}
              onActionToClose={() => setSectionOpened(null)}
              help={help.find((h) => h.tag === 'ambassador_setup_landing_page')}
              helpChildren={
                <p>
                  {
                    help.find((h) => h.tag === 'ambassador_setup_landing_page')
                      ?.text
                  }
                </p>
              }
            >
              <div className={styles.form__row}>
                <div className={styles.field__bgImg__wrapper}>
                  <div
                    className={clsx(
                      styles.field__bgImg__preview,
                      formik.touched.landingBgImgURL &&
                        formik.errors.landingBgImgURL &&
                        styles['field__bgImg__preview--error']
                    )}
                  >
                    {formik.values.landingBgImgURL ? (
                      <img
                        src={
                          typeof formik.values.landingBgImgURL === 'string'
                            ? formik.values.landingBgImgURL
                            : URL.createObjectURL(formik.values.landingBgImgURL)
                        }
                        alt="Landing page background"
                      />
                    ) : (
                      <ImagePlaceholderFillIcon
                        className={styles.field__bgImg__placeholder}
                      />
                    )}
                  </div>

                  <div
                    className={clsx(
                      styles.field__bgImg,
                      formik.values.landingBgImgURL &&
                        styles['field__bgImg--change']
                    )}
                  >
                    <input
                      ref={landingBgFileRef}
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleLandingBgFileUpload}
                    />
                    {formik.values.landingBgImgURL ? null : (
                      <>
                        <span className={styles.field__bgImg__text}>
                          Background Image
                        </span>
                        <span className={styles.field__bgImg__text__desc}>
                          1440x1200 recommended
                        </span>
                      </>
                    )}
                    <PrimaryButton
                      disabled={isLocked}
                      onClick={() => landingBgFileRef.current.click()}
                      className={styles.button__flex}
                    >
                      {formik.values.landingBgImgURL ? (
                        <>
                          <div
                            style={{ alignItems: 'center', display: 'flex' }}
                          >
                            <ImagePlaceholderIcon
                              className={clsx(
                                styles.button__uploadBgImg__icon,
                                isLocked &&
                                  styles['button__uploadBgImg__icon--disabled']
                              )}
                            />

                            <p
                              style={{ lineHeight: '2rem', display: 'inline' }}
                            >
                              Change
                            </p>
                          </div>
                          <p style={{ fontSize: '8px', lineHeight: '1.4rem' }}>
                            Image max size - 1mb
                          </p>
                        </>
                      ) : (
                        'Upload Image'
                      )}
                    </PrimaryButton>
                  </div>
                </div>
              </div>

              <div className={styles.form__row}>
                <div className={styles.form__col}>
                  <TextArea
                    tabIndex="1"
                    rows="2"
                    label="Title"
                    disabled={isLocked}
                    placeholder="Title"
                    value={formik.values.landingTitle}
                    onChange={(value) =>
                      formik.setFieldValue('landingTitle', value)
                    }
                    {...getFormikCaptionTextData(formik, 'landingTitle', {
                      captionText: `${formik.values.landingTitle.length}/70`,
                    })}
                  />

                  <TextInput
                    tabIndex="3"
                    label="Button Text"
                    disabled={isLocked}
                    placeholder="Button Text"
                    value={formik.values.landingButtonText}
                    onChange={(value) =>
                      formik.setFieldValue('landingButtonText', value)
                    }
                    {...getFormikCaptionTextData(formik, 'landingButtonText', {
                      captionText: `${formik.values.landingButtonText.length}/20`,
                    })}
                  />
                </div>

                <div className={styles.form__col}>
                  <TextArea
                    tabIndex="2"
                    rows="2"
                    label="Subtitle"
                    disabled={isLocked}
                    placeholder="Subtitle"
                    value={formik.values.landingDesc}
                    onChange={(value) =>
                      formik.setFieldValue('landingDesc', value)
                    }
                    {...getFormikCaptionTextData(formik, 'landingDesc', {
                      captionText: `${formik.values.landingDesc.length}/200`,
                    })}
                  />

                  <div className={styles.landing__preview__container}>
                    <label>Preview</label>
                    <div className={styles.landing__preview__button__wrapper}>
                      <SecondaryButton
                        className={styles.landing__preview__button}
                        label="Preview"
                        onClick={handleClickLandingPagePreview}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AccordionSection>

            <AccordionSection
              className={styles.accordion_section}
              title="Invitation Email from Camp Tree"
              description="Invite Ambassador via Camp Tree Email"
              opened={sectionOpened === 'email'}
              onActionToOpen={() => setSectionOpened('email')}
              onActionToClose={() => setSectionOpened(null)}
              help={help.find(
                (h) => h.tag === 'ambassador_setup_invitation_email'
              )}
              helpChildren={
                <p>
                  {
                    help.find(
                      (h) => h.tag === 'ambassador_setup_invitation_email'
                    )?.text
                  }
                </p>
              }
            >
              <EmailTemplateSection
                subject={{
                  id: 'emailSubject',
                  value: formik.values.emailSubject,
                  ...getFormikCaptionTextData(formik, 'emailSubject'),
                }}
                heading={{
                  id: 'emailHeading',
                  value: formik.values.emailHeading,
                  ...getFormikCaptionTextData(formik, 'emailHeading'),
                }}
                subheading={{
                  id: 'emailSubheading',
                  value: formik.values.emailSubheading,
                  ...getFormikCaptionTextData(formik, 'emailSubheading'),
                }}
                content={{
                  id: 'emailContent',
                  value: formik.values.emailContent,
                  ...getFormikCaptionTextData(formik, 'emailContent'),
                }}
                isLocked={isLocked}
                onChange={(id, value) => {
                  formik.setFieldValue(id, value)
                  // formik.validateField(id)
                  //   .then( result => console.log(`validating ${id} = ${value}`) )
                }}
                onPreview={handleClickEmailPreview}
                optionalTags={optionalTags}
                requiredTags={requiredTags}
                templateVars={templateVars}
              />
            </AccordionSection>

            <AccordionSection
              className={styles.accordion_section}
              title="Public Link for Camps"
              description="Invite Ambassador via Public Link"
              opened={sectionOpened === 'public-link'}
              onActionToOpen={() => setSectionOpened('public-link')}
              onActionToClose={() => setSectionOpened(null)}
              help={help.find((h) => h.tag === 'ambassador_setup_public_link')}
              helpChildren={
                <p>
                  {
                    help.find((h) => h.tag === 'ambassador_setup_public_link')
                      ?.text
                  }
                </p>
              }
            >
              <div className={styles.form__row}>
                <TextInput
                  classNames={{ container: styles.field__public_link__input }}
                  label="&nbsp;"
                  disabled={isLocked}
                  value={publicLink}
                  readOnly
                />

                <PrimaryButton
                  label="Copy"
                  className={styles.field__public_link__copy}
                  onClick={handleClickCopyPublicLink}
                />
              </div>

              <p
                className={styles.field__public_link__desc}
                dangerouslySetInnerHTML={{
                  __html:
                    'The Public Link is activated AFTER the campaign is launched. Please proceed.',
                }}
              />
            </AccordionSection>
            <AccordionSection
              className={styles.accordion_section}
              title="Landing Page Widget"
              description="Iframe to embed landing page in websites"
              opened={sectionOpened === 'widget-code'}
              onActionToOpen={() => setSectionOpened('widget-code')}
              onActionToClose={() => setSectionOpened(null)}
              help={help.find((h) => h.tag === 'ambassador_setup_widget')}
              helpChildren={
                <p>
                  {help.find((h) => h.tag === 'ambassador_setup_widget')?.text}
                </p>
              }
            >
              <div className={styles.form__row}>
                <PrimaryButton
                  label="Copy Widget Code"
                  className={styles.field__public_link__copy_widget}
                  onClick={handleClickCopyWidgetCode}
                />
              </div>

              <p
                className={styles.field__public_link__desc}
                dangerouslySetInnerHTML={{
                  __html:
                    'The Widget is activated AFTER the campaign is launched. Please proceed.',
                }}
              />
            </AccordionSection>
          </fieldset>

          <fieldset ref={dashboardRef} className={styles.section__dashboard}>
            <div className={styles.dashboard__wrapper}>
              <div className={styles.profile_container}>
                <h4 className={styles.section__title}>
                  Dashboard
                  <span className={styles.section__description}>
                    Create Ambassador Dashboard
                  </span>
                </h4>
                {help.find((h) => h.tag === 'ambassador_setup_dashboard') && (
                  <HelpModal
                    className={styles.helpModal}
                    help={help.find(
                      (h) => h.tag === 'ambassador_setup_dashboard'
                    )}
                  >
                    <p>
                      {
                        help.find((h) => h.tag === 'ambassador_setup_dashboard')
                          ?.text
                      }
                    </p>
                  </HelpModal>
                )}
              </div>
              <div className={clsx(styles.form, styles.dashboard__info)}>
                <div className={styles.form__row}>
                  <div className={styles.form__col}>
                    <TextArea
                      classNames={{ textarea: styles.textarea }}
                      rows="2"
                      tabIndex="7"
                      label="Heading"
                      disabled={isLocked}
                      placeholder="Heading"
                      value={formik.values.dashboardHeading}
                      onChange={(value) =>
                        formik.setFieldValue('dashboardHeading', value)
                      }
                      {...getFormikCaptionTextData(formik, 'dashboardHeading', {
                        captionText: `${formik.values.dashboardHeading.length}/65`,
                      })}
                    />
                  </div>

                  <div className={styles.form__col}>
                    <TextArea
                      tabIndex="8"
                      rows="2"
                      classNames={{ textarea: styles.textarea }}
                      label="Subheading"
                      disabled={isLocked}
                      placeholder="Subheading"
                      value={formik.values.dashboardSubheading}
                      onChange={(value) =>
                        formik.setFieldValue('dashboardSubheading', value)
                      }
                      {...getFormikCaptionTextData(
                        formik,
                        'dashboardSubheading',
                        {
                          captionText: `${formik.values.dashboardSubheading.length}/65`,
                        }
                      )}
                    />
                  </div>
                </div>
              </div>

              <AccordionSection
                className={styles.accordion_section}
                title="Terms of Use"
                opened
                isDisabledToggle
                help={help.find((h) => h.tag === 'ambassador_setup_terms')}
                helpChildren={
                  <p>
                    {help.find((h) => h.tag === 'ambassador_setup_terms')?.text}
                  </p>
                }
              >
                <HtmlEditor
                  disabled={isLocked}
                  editorRef={termsRef}
                  value={formik.values.terms}
                  isPreviewHidden={true}
                  onChange={() =>
                    termsRef.current
                      ? formik.setFieldValue(
                          'terms',
                          termsRef.current.getContent()
                        )
                      : null
                  }
                  {...getFormikCaptionTextData(formik, 'terms')}
                />
              </AccordionSection>

              <div className={styles.dashboard__preview__button__wrapper}>
                <SecondaryButton
                  className={styles.dashboard__preview__button}
                  label="Preview"
                  onClick={handleClickDashboardPagePreview}
                />
              </div>
            </div>
          </fieldset>

          <fieldset ref={emailsRef} className={styles.section__emails}>
            <div className={styles.profile_container}>
              <h4 className={styles.section__title}>Emails to Ambassador</h4>
              {help.find((h) => h.tag === 'ambassador_setup_emails') && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find((h) => h.tag === 'ambassador_setup_emails')}
                >
                  <p>
                    {
                      help.find((h) => h.tag === 'ambassador_setup_emails')
                        ?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            <div className={styles.emails_to__wrapper}>
              {[
                MESSAGE.A__EMAIL,
                MESSAGE.A__ENROLL,
                MESSAGE.A__REFERRAL,
                MESSAGE.A__REGISTRATION,
              ].map((TEMPLATE, index) => (
                <div className={styles.field__email_to} key={index}>
                  <Checkbox
                    className={styles.field__email_to__checkbox}
                    label={TEMPLATE.LABEL}
                    disabled={isLocked}
                    readOnly={true}
                    isChecked={true}
                    help={help.find((h) => h.tag === TEMPLATE.HELP)}
                    helpChildren={
                      <p>{help.find((h) => h.tag === TEMPLATE.HELP)?.text}</p>
                    }
                  />

                  <TertiaryButton
                    disabled={isLocked}
                    onClick={() => handleClickEmailEditor(TEMPLATE)}
                  >
                    <PencilIcon
                      className={clsx(
                        styles.button__email__icon,
                        isLocked && styles['button__email__icon--disabled']
                      )}
                    />
                    Edit
                  </TertiaryButton>

                  <SecondaryButton
                    onClick={() =>
                      window
                        .open(
                          `${PATH.PREVIEW__AS__EMAIL}?campaignId=${campaignId}&messageType=${TEMPLATE.TYPE}`,
                          '_blank'
                        )
                        .focus()
                    }
                  >
                    <EyeVisibleColorIcon
                      className={clsx(
                        styles.button__email__icon,
                        isLocked && styles['button__email__icon--disabled']
                      )}
                    />
                    View
                  </SecondaryButton>
                </div>
              ))}
            </div>
          </fieldset>

          <div className={styles.section__footer}>
            <SecondaryButton
              className={styles.button__back}
              label="Back"
              onClick={onBack}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save"
              onClick={() => handleSubmitOnly()}
            />

            <PrimaryButton
              className={styles.button__next}
              label="Save & Next"
              onClick={() => handleClickSubmit(true)}
            />

            <SecondaryButton
              label="Save & Close"
              onClick={() => handleClickSubmit(false)}
            />
          </div>
        </form>
      </div>

      <EmailTemplateEditorModal
        campaignId={campaignId}
        setupType="AS"
        {...emailTemplateToEdit}
        show={isEmailEditorOpened}
        onClose={() => setEmailEditorOpened(false)}
        isLocked={isLocked}
      />

      <LoadingOverlay isLoading={isLoading} />
    </>
  )
}

export default AmbassadorSetup
