import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './YourProfile.module.scss'
import {
  TextInput,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  LockIcon,
  ChangePasswordModal,
  HelpModal,
} from 'components'
import { useStateValue } from 'state'
import { ERR_CODE, PATH } from 'utils/constants'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'hooks'

const YourProfile = () => {
  const {
    state: { auth, deviceSize, help },
    action: { updateUserProfile },
  } = useStateValue()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: auth.user.name || '',
      email: auth.user.email || '',
      phone: auth.user.phone || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter your name.'),
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
      phone: Yup.string().matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        'Please enter a valid phone number.'
      ),
    }),
    onSubmit: ({ name, email, phone }) =>
      updateUserProfile({ name, email, phone }, () =>
        navigate({ pathname: PATH.CAMPS })
      ),
  })

  useEffect(() => {
    switch (auth.code) {
      case ERR_CODE.AUTH_EMAIL:
        formik.setFieldError('email', auth.message)
        break

      case ERR_CODE.AUTH_PHONE:
        formik.setFieldError('phone', auth.message)
        break

      case ERR_CODE.AUTH_NAME:
        formik.setFieldError('name', auth.message)
        break

      case ERR_CODE.AUTH_TOKEN:
      case ERR_CODE.AUTH_UNKNOWN:
        // alert(auth.message);
        break

      default:
        break
    }
  }, [auth.code, auth.message, formik])

  return (
    <>
      <div className={styles.page}>
        <div className={styles.profile_container}>
          {['xs', 'sm', 'md'].includes(deviceSize) ? (
            <h4
              className={styles.page__title}
              dangerouslySetInnerHTML={{ __html: 'Your profile' }}
            />
          ) : (
            <h2
              className={styles.page__title}
              dangerouslySetInnerHTML={{ __html: 'Your profile' }}
            />
          )}
          {help.find((h) => h.tag === 'user_profile_overview') && (
            <HelpModal
              className={styles.helpModal}
              help={help.find((h) => h.tag === 'user_profile_overview')}
            >
              <p>{help.find((h) => h.tag === 'user_profile_overview')?.text}</p>
            </HelpModal>
          )}
        </div>
        <form className={styles.form}>
          <legend className={styles.form__legend}>User</legend>
          <div className={styles.form__row}>
            <TextInput
              classNames={{ container: styles.form__field }}
              label="Name"
              value={formik.values.name}
              onChange={(name) => formik.setFieldValue('name', name)}
              {...(formik.touched.name && formik.errors.name
                ? { isError: true, captionText: formik.errors.name }
                : {})}
              requiredShown
            />

            <TextInput
              classNames={{ container: styles.form__field }}
              label="Email"
              value={formik.values.email}
              onChange={(email) => formik.setFieldValue('email', email)}
              {...(formik.touched.email && formik.errors.email
                ? { isError: true, captionText: formik.errors.email }
                : {})}
              requiredShown
            />
          </div>

          <div className={styles.form__row}>
            <TextInput
              classNames={{ container: styles.form__field }}
              label="Phone"
              value={formik.values.phone}
              onChange={(phone) => formik.setFieldValue('phone', phone)}
              {...(formik.touched.phone && formik.errors.phone
                ? { isError: true, captionText: formik.errors.phone }
                : {})}
            />
          </div>

          <div className={clsx(styles.form__row, styles.form__row__password)}>
            <TertiaryButton
              className={styles.form__change_password}
              onClick={() => setShowModal(true)}
            >
              <LockIcon /> Change password
            </TertiaryButton>
            {help.find(
              (h) => h.tag === 'user_profile_user_change_password'
            ) && (
              <HelpModal
                help={help.find(
                  (h) => h.tag === 'user_profile_user_change_password'
                )}
              >
                <p>
                  {
                    help.find(
                      (h) => h.tag === 'user_profile_user_change_password'
                    )?.text
                  }
                </p>
              </HelpModal>
            )}
          </div>

          <div className={styles.form__buttons}>
            <SecondaryButton
              className={styles.form__cancel}
              label="Cancel"
              onClick={() => {
                formik.resetForm()
                navigate({ pathname: PATH.CAMPS })
              }}
            />

            <PrimaryButton
              className={styles.form__save}
              label="Save"
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>

      <ChangePasswordModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default YourProfile
