import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from 'react'
import mainReducers from './reducers'
import mainActions from './actions'
import { readAuthState } from 'utils'

const getInitialState = () => ({
  isLoading: false,
  error: null,
  help: [],
  auth: { state: readAuthState(), user: null },
  camp: {
    activeCamp: null,
    otherCamps: [],
  },
  campaign: {
    incompleteCampaigns: [],
    activeCampaigns: [],
    closedCampaigns: [],
    archivedCampaigns: [],
  },
})

export const StateContext = createContext([getInitialState(), () => null])

export const StateProvider = ({ children, _state }) => {
  const inititalState = _state ?? getInitialState()
  const [state, dispatch] = useReducer(mainReducers, inititalState)
  const [deviceSize, setDeviceSize] = useState()

  const actions = {}

  Object.keys(mainActions).forEach(
    (actionName) => (actions[actionName] = mainActions[actionName](dispatch))
  )

  const handleWindowResize = () => {
    const { clientWidth } = document.documentElement

    if (clientWidth >= 1400) setDeviceSize('xxl')
    else if (clientWidth >= 1200) setDeviceSize('xl')
    else if (clientWidth >= 992) setDeviceSize('lg')
    else if (clientWidth >= 768) setDeviceSize('md')
    else if (clientWidth >= 576) setDeviceSize('sm')
    else setDeviceSize('xs')
  }

  useEffect(() => {
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <StateContext.Provider
      value={{ state: { ...state, deviceSize }, action: actions }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateValue = () => useContext(StateContext)
