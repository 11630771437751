import globalReducer from './global'
import authReducer from './auth'
import campReducer from './camp'
import campaignReducer from './campaign'

const mainReducer = (
  { isLoading, error, help, auth, camp, campaign },
  action
) => ({
  ...globalReducer({ isLoading, help, error }, action),
  auth: authReducer(auth, action),
  camp: campReducer(camp, action),
  campaign: campaignReducer(campaign, action),
})

export default mainReducer
