import React, { useState } from 'react'
import styles from './HelpModal.module.scss'
import { ExcludedIcon, HelpIconSmall, Modal, SecondaryButton } from 'components'

const HelpModal = ({ className = '', help, children }) => {
  const [showHelp, setShowHelp] = useState(false)

  return help ? (
    <div className={className}>
      <div className={styles.form__header__desc}>
        <HelpIconSmall
          className={styles.help_icon}
          onClick={() => {
            setShowHelp(true)
          }}
        />
      </div>
      <Modal
        classNames={{ modal: styles.modal }}
        show={showHelp}
        onClose={() => {
          setShowHelp(false)
        }}
      >
        <h6 className={styles.title}>{help.title ?? 'Help'}</h6>

        <p className={styles.body}>{children}</p>
        {help.link && (
          <div className={styles.learn_more}>
            <a href={help.link} target="_blank">
              Learn More
            </a>
          </div>
        )}
        <button
          type="button"
          className={styles.close}
          onClick={() => {
            setShowHelp(false)
          }}
        >
          <ExcludedIcon />
        </button>
      </Modal>
    </div>
  ) : null
}

export default HelpModal
