import React, { useEffect, useRef, useState } from 'react'
import {
  CampaignItem,
  CreateCampaignButton,
  BookmarkIcon,
  SearchInput,
  HelpModal,
} from 'components'
import styles from './Campaigns.module.scss'
import { useStateValue } from 'state'
import { useNavigate, useClickOutside } from 'hooks'
import {
  CAMPAIGN_STATUS,
  STORAGE_NAME,
  CAMPAIGN_TYPE,
  PATH,
} from 'utils/constants'
import clsx from 'clsx'
import { getStorageItem, getCampaignTypeAsText } from 'utils'
import Toggle from 'react-toggle'
import { closeCampaign } from 'state/actions/campaign'

const Campaigns = () => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const {
    state: {
      campaign: {
        incompleteCampaigns,
        activeCampaigns,
        closedCampaigns,
        archivedCampaigns,
      },
      deviceSize,
      help,
    },
    action: { getAllCampaigns, getDefaultCampaign },
  } = useStateValue()
  const navigate = useNavigate()
  const refItems = {
    [CAMPAIGN_STATUS.INCOMPLETE]: useRef(null),
    [CAMPAIGN_STATUS.ACTIVE]: useRef(null),
    [CAMPAIGN_STATUS.CLOSED]: useRef(null),
    createCampaignMenu: useRef(null),
  }
  const [selectedStatus, setSelectedStatus] = useState(
    CAMPAIGN_STATUS.INCOMPLETE
  )
  const [archivedActive, setArchivedActive] = useState(false)
  const [isNewMenuOpened, setNewMenuOpened] = useState(false)

  useClickOutside(refItems.createCampaignMenu, () => setNewMenuOpened(false))

  // eslint-disable-next-line
  useEffect(
    () =>
      getAllCampaigns(
        campIdInUse,
        () => null,
        () => navigate({ pathname: PATH.CAMPS })
      ),
    [campIdInUse]
  )

  const handleCreateCampagin = (campaignType) =>
    getDefaultCampaign({ campId: campIdInUse, campaignType }, (campaign) =>
      navigate({
        pathname: `${PATH.CAMPAIGNS__EDIT}/define/${campaign.id}`,
        state: { campaign },
      })
    )

  return (
    <div className={styles.page}>
      <div className={styles.page__top}>
        <div className={styles.create_campaign__wrapper}>
          <div className={styles.create_campaign__wrapper__help}>
            <CreateCampaignButton />
            {help.find((h) => h.tag === 'campaigns_create_campaign') && (
              <HelpModal
                className={styles.help}
                help={help.find((h) => h.tag === 'campaigns_create_campaign')}
              >
                <p>
                  {help.find((h) => h.tag === 'campaigns_create_campaign')?.text}
                </p>
              </HelpModal>
            )}
          </div>
          <div
            ref={refItems.createCampaignMenu}
            className={clsx(
              styles.create_campaign__menu,
              isNewMenuOpened && styles['create_campaign__menu--opened']
            )}
          >
            {[
              CAMPAIGN_TYPE.REGISTRATION,
              CAMPAIGN_TYPE.COUNSELOR,
              CAMPAIGN_TYPE.CUSTOM,
            ].map((type, mIdx) => (
              <button
                key={mIdx}
                className={styles.create_campaign__menu__item}
                onClick={() => handleCreateCampagin(type)}
              >
                {getCampaignTypeAsText(type)}
              </button>
            ))}
          </div>
        </div>

        {incompleteCampaigns.length +
          activeCampaigns.length +
          closedCampaigns.length && false ? (
          <SearchInput
            classNames={{ container: styles.page__top__search }}
            placeholder="Search"
          />
        ) : null}
      </div>

      <div className={styles.page__content__wrapper}>
        <div className={styles.page__content}>
          {[
            {
              campaigns: incompleteCampaigns,
              status: CAMPAIGN_STATUS.INCOMPLETE,
            },
            { campaigns: activeCampaigns, status: CAMPAIGN_STATUS.ACTIVE },
          ].map(({ campaigns, status }) => (
            <div ref={refItems[status]} className={styles.section} key={status}>
              <div
                className={clsx(
                  styles.section__header,
                  styles[`section__header--${status}`]
                )}
              >
                <span>{status.toLowerCase()}</span>
                <span>{campaigns.length}</span>
              </div>

              {campaigns.length ? (
                <div
                  className={clsx(
                    styles.section__content,
                    styles[`section__content--${status}`]
                  )}
                >
                  <div
                    className={clsx(
                      styles.section__content__inner,
                      styles[`section__content__inner--${status}`]
                    )}
                  >
                    {campaigns.map((campaign, cIndex) => (
                      <CampaignItem
                        className={styles.section__item}
                        key={cIndex}
                        campaign={campaign}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(
                    styles.section__content,
                    styles.section__content__no_items,
                    styles[`section__content__no_items--${status}`]
                  )}
                >
                  <BookmarkIcon />
                  <p>
                    No <span>{status.toLowerCase()}</span> Campaign yet
                  </p>
                </div>
              )}
            </div>
          ))}
          <div
            ref={
              refItems[
                archivedActive
                  ? CAMPAIGN_STATUS.ARCHIVED
                  : CAMPAIGN_STATUS.CLOSED
              ]
            }
            className={styles.section}
            key={
              archivedActive ? CAMPAIGN_STATUS.ARCHIVED : CAMPAIGN_STATUS.CLOSED
            }
          >
            <div
              className={clsx(
                styles.section__header,
                styles[
                  `section__header--${
                    archivedActive
                      ? CAMPAIGN_STATUS.ARCHIVED
                      : CAMPAIGN_STATUS.CLOSED
                  }`
                ]
              )}
            >
              <span>{CAMPAIGN_STATUS.CLOSED.toLowerCase()}</span>
              <span>{closedCampaigns.length}</span>
              <Toggle
                defaultChecked={archivedActive}
                icons={{
                  checked: null,
                  unchecked: null,
                }}
                onChange={() => setArchivedActive(!archivedActive)}
              />
              <span>Archived</span>
              <span>{archivedCampaigns.length}</span>
            </div>

            {archivedActive ? (
              archivedCampaigns.length ? (
                <div
                  className={clsx(
                    styles.section__content,
                    styles[`section__content--${CAMPAIGN_STATUS.ARCHIVED}`]
                  )}
                >
                  <div
                    className={clsx(
                      styles.section__content__inner,
                      styles[
                        `section__content__inner--${CAMPAIGN_STATUS.ARCHIVED}`
                      ]
                    )}
                  >
                    {archivedCampaigns.map((campaign, cIndex) => (
                      <CampaignItem
                        className={styles.section__item}
                        key={cIndex}
                        campaign={campaign}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className={clsx(
                    styles.section__content,
                    styles.section__content__no_items,
                    styles[
                      `section__content__no_items--${CAMPAIGN_STATUS.ARCHIVED}`
                    ]
                  )}
                >
                  <BookmarkIcon />
                  <p>
                    No <span>{CAMPAIGN_STATUS.ARCHIVED.toLowerCase()}</span>{' '}
                    Campaign yet
                  </p>
                </div>
              )
            ) : closedCampaigns.length ? (
              <div
                className={clsx(
                  styles.section__content,
                  styles[`section__content--${CAMPAIGN_STATUS.CLOSED}`]
                )}
              >
                <div
                  className={clsx(
                    styles.section__content__inner,
                    styles[`section__content__inner--${CAMPAIGN_STATUS.CLOSED}`]
                  )}
                >
                  {closedCampaigns.map((campaign, cIndex) => (
                    <CampaignItem
                      className={styles.section__item}
                      key={cIndex}
                      campaign={campaign}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  styles.section__content,
                  styles.section__content__no_items,
                  styles[
                    `section__content__no_items--${CAMPAIGN_STATUS.CLOSED}`
                  ]
                )}
              >
                <BookmarkIcon />
                <p>
                  No <span>{CAMPAIGN_STATUS.CLOSED.toLowerCase()}</span>{' '}
                  Campaign yet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {!['xxl'].includes(deviceSize) && (
        <div className={styles.page__bottom__wrapper}>
          <div className={styles.page__bottom}>
            {[
              CAMPAIGN_STATUS.INCOMPLETE,
              CAMPAIGN_STATUS.ACTIVE,
              CAMPAIGN_STATUS.CLOSED,
            ].map((status) => (
              <button
                type="button"
                key={status}
                className={clsx(
                  styles.button__status,
                  status === selectedStatus &&
                    styles['button__status--selected']
                )}
                dangerouslySetInnerHTML={{ __html: status.toLowerCase() }}
                onClick={(e) => {
                  e.preventDefault()

                  setSelectedStatus(status)
                  refItems[status].current.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Campaigns
