import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import styles from './Dashboard.module.scss'
import {
  Badge,
  Card,
  Select,
  MinusCircleIcon,
  CheckMarkCircleIcon,
  EnvelopeIcon,
  CreateCampaignButton,
  HelpModal,
} from 'components'
import { useNavigate } from 'hooks'
import { CAMPAIGN_STATUS, PATH, STORAGE_NAME } from 'utils/constants'
import { useStateValue } from 'state'
import { getStorageItem } from 'utils'
import moment from 'moment'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'

const Dashboard = () => {
  const navigate = useNavigate()
  const campId = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const {
    action: { getCampaignSummaries },
    state: { help },
  } = useStateValue()
  const [summaries, setSummaries] = useState([])
  const [statusFilter, setStatusFilter] = useState('ALL')
  const [campaignFilterValue, setCampaignFilterValue] =
    useState('All Campaigns')

  useEffect(
    () =>
      getCampaignSummaries(campId, statusFilter, (summaries) => {
        setSummaries(summaries)
      }),
    // eslint-disable-next-line
    [campId, statusFilter]
  )

  const incompleteCampaigns = summaries.filter(
    (c) => c.status === CAMPAIGN_STATUS.INCOMPLETE
  )
  const activeCampaigns = summaries.filter(
    (c) => c.status === CAMPAIGN_STATUS.ACTIVE
  )
  const closedCampaigns = summaries.filter(
    (c) => c.status === CAMPAIGN_STATUS.CLOSED
  )

  const campaignFilterOptions = [
    [
      `All Campaigns`,
      // ${
      //   summaries.length === 0 ? '' : '(' + summaries.length + ')'
      // }`,
      'ALL',
    ],
    [
      `Incomplete Campaigns`,
      //  ${
      //   incompleteCampaigns.length === 0
      //     ? ''
      //     : '(' + incompleteCampaigns.length + ')'
      // }`,
      CAMPAIGN_STATUS.INCOMPLETE,
    ],
    [
      `Active Campaigns`,
      //  ${
      //   activeCampaigns.length === 0 ? '' : '(' + activeCampaigns.length + ')'
      // }`,
      CAMPAIGN_STATUS.ACTIVE,
    ],
    [
      `Closed Campaigns`,
      //  ${
      //   closedCampaigns.length === 0 ? '' : '(' + closedCampaigns.length + ')'
      // }`,
      CAMPAIGN_STATUS.CLOSED,
    ],
  ]
  const dateValue = new Date()
  const searchValue = ''

  const handleCampaignClick = (campaign) =>
    navigate({
      pathname: [CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.CLOSED].includes(
        campaign?.status
      )
        ? `${PATH.CAMPAIGNS__EDIT}/performance/${campaign.id}`
        : `${PATH.CAMPAIGNS__EDIT}/define/${campaign.id}`,
    })

  const handleStatusFilterChange = (value) => {
    const found = campaignFilterOptions.find((e) => e[0] === value) || [
      0,
      'ALL',
    ]
    setStatusFilter(found[1])
    setCampaignFilterValue(value)
  }

  const totalJoined = summaries.reduce(
    (prev, curr) => prev + parseInt(curr.joinedCount),
    0
  )
  const totalInvited = summaries.reduce(
    (prev, curr) => prev + parseInt(curr.invitedCount),
    0
  )

  const totalRegistered = summaries.reduce(
    (prev, curr) => prev + parseInt(curr.registrationCount),
    0
  )
  const totalReferred = summaries.reduce(
    (prev, curr) => prev + parseInt(curr.referralCount),
    0
  )

  const columns = [
    {
      name: 'Campaign Name',
      selector: (campaign) => (
        <>
          <span
            className={styles.table__data_hyperlink}
            onClick={() => handleCampaignClick(campaign)}
          >
            {campaign.name}{' '}
          </span>
          {campaign.testMode ? (
            <span className={styles.table__test_campaign}> (Test) </span>
          ) : (
            ''
          )}
        </>
      ),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Start Date',
      selector: (campaign) =>
        new Date(campaign.startDate).toISOString().slice(0, 10),
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (campaign) =>
        new Date(campaign.endDate).toISOString().slice(0, 10),
      sortable: true,
    },
    {
      name: 'Ambassadors',
      selector: (campaign) => (
        <div style={{ textAlign: 'center' }}>
          {campaign.testMode ? (
            campaign.ambassadorCount
          ) : (
            <Link
              className={styles.table__data_number}
              to={{
                pathname: `${PATH.AMBASSADORS}`,
                search: `?campaign=${campaign.id}&status=Joined`,
              }}
            >
              {campaign.ambassadorCount}
            </Link>
          )}
        </div>
      ),
    },
    {
      name: 'Learn More (LM)',
      selector: (campaign) => (
        <div style={{ textAlign: 'center' }}>
          {campaign.testMode ? (
            campaign.inquiryCount
          ) : (
            <Link
              className={styles.table__data_number}
              to={{
                pathname: `${PATH.PROSPECTS}`,
                search: `?campaign=${campaign.id}&status=inquiry`,
              }}
            >
              {campaign.inquiryCount}
            </Link>
          )}
        </div>
      ),
    },
    {
      name: 'Claim Code (CC)',
      selector: (campaign) => (
        <div style={{ textAlign: 'center' }}>
          {campaign.testMode ? (
            campaign.promoCodeCount
          ) : (
            <Link
              className={styles.table__data_number}
              to={{
                pathname: `${PATH.PROSPECTS}`,
                search: `?campaign=${campaign.id}&status=promocode`,
              }}
            >
              {campaign.promoCodeCount}
            </Link>
          )}
        </div>
      ),
    },
    {
      name: 'Referrals',
      selector: (campaign) => (
        <div style={{ textAlign: 'center' }}>
          {campaign.testMode ? (
            campaign.referralCount
          ) : (
            <Link
              className={styles.table__data_number}
              to={{
                pathname: `${PATH.PROSPECTS}`,
                search: `?campaign=${campaign.id}`,
              }}
            >
              {campaign.referralCount}
            </Link>
          )}
        </div>
      ),
    },
    {
      name: 'Registrations',
      selector: (campaign) => (
        <div style={{ textAlign: 'center' }}>
          {campaign.testMode ? (
            campaign.registrationCount
          ) : (
            <Link
              className={styles.table__data_number}
              to={{
                pathname: `${PATH.PROSPECTS}`,
                search: `?campaign=${campaign.id}&status=registered`,
              }}
            >
              {campaign.registrationCount}
            </Link>
          )}
        </div>
      ),
    },
    {
      name: 'Status',
      selector: (campaign) => (campaign.testMode ? 'TEST' : campaign.status),
      sortable: true,
    },
  ]

  return (
    <div className={styles.page}>
      {/* <!-- Row 1 --> */}
      <Select
        label="Filter"
        options={campaignFilterOptions.map((v) => v[0])}
        value={campaignFilterValue}
        onChange={handleStatusFilterChange}
        help={help.find((h) => h.tag === 'dashboard_sort_status')}
        helpChildren={
          <p>{help.find((h) => h.tag === 'dashboard_sort_status')?.text}</p>
        }
      />
      <div></div>
      <div className={styles.dashboard__overview}>
        {help.find((h) => h.tag === 'dashboard_overview') && (
          <HelpModal
            className={styles.help}
            help={help.find((h) => h.tag === 'dashboard_overview')}
          >
            <p>{help.find((h) => h.tag === 'dashboard_overview')?.text}</p>
          </HelpModal>
        )}
      </div>
      {/* <DatePicker date={dateValue} />
      <TextInput placeholder="Search" value={searchValue} /> */}

      {/* Notifications panel */}
      <div className={clsx([styles.notifications, styles.page__right_column])}>
        <h3
          className={clsx(
            'headline-6-neutral',
            clsx(styles.notifications__title)
          )}
        >
          Notifications
          <Badge className={styles.badge}>3</Badge>
        </h3>
        <p className={styles.notifications__date}>{moment().calendar()}</p>

        {/* Notifications card - Registration */}
        <div className={styles.notifications__card}>
          <div className={styles.notifications__card_type}>Registration</div>
          <div className={styles.notifications__card_icons}>
            <MinusCircleIcon
              className={styles.notifications__card_icon}
              title="Reject registration"
            />
            <CheckMarkCircleIcon
              className={styles.notifications__card_icon}
              title="Approve registration"
            />
          </div>
          <div>
            <p className={styles.notifications__card_data_main}>
              John Smith (2)
            </p>
            <p className={styles.notifications__card_data}>
              Referred By: Jane Doe <br />
              <span>{moment().subtract(3, 'days').format('l')}</span>
            </p>
            <p className={styles.notifications__card_data}>Fill Your Cabin!</p>
          </div>
        </div>

        {/* Notifications card - Referral*/}
        <div className={styles.notifications__card}>
          <div className={styles.notifications__card_type}>New Referral</div>
          <div className={styles.notifications__card_icons}>
            <MinusCircleIcon
              className={styles.notifications__card_icon}
              title="Dismiss notification"
            />
            <EnvelopeIcon
              className={styles.notifications__card_icon}
              title="Email Jane Doe"
            />
            <EnvelopeIcon
              className={styles.notifications__card_icon}
              title="Email John Smith"
            />
          </div>
          <div>
            <p className={styles.notifications__card_data_main}>
              John Smith (2)
            </p>
            <p className={styles.notifications__card_data}>
              Referred By: Jane Doe <br />
              <span>{moment().subtract(3, 'days').format('l')}</span>
            </p>
            <p className={styles.notifications__card_data}>Fill Your Cabin!</p>
          </div>
        </div>

        {/* Notifications card - Campaign Ending */}
        <div className={styles.notifications__card}>
          <div className={styles.notifications__card_type}>Campaign Ending</div>
          <div className={styles.notifications__card_icons}>
            <MinusCircleIcon
              className={styles.notifications__card_icon}
              title="Stop Showing"
            />
          </div>
          <div>
            <p className={styles.notifications__card_data}>
              Campaign &quot;Fill Your Cabin!&quot; ending: &nbsp;
              {moment().add(3, 'days').calendar()}
            </p>
          </div>
        </div>
      </div>

      {/* <!-- Row 2 --> */}
      <Card>
        <h2 className={clsx(['headline-4', styles.campaigns__headline])}>
          All Campaigns
          <span className={clsx('headline-6-green', styles.campaigns__count)}>
            {summaries.length}
          </span>
        </h2>
        <div className={styles.campaigns__create}>
          <CreateCampaignButton />
          {help.find((h) => h.tag === 'dashboard_create_campaign') && (
            <HelpModal
              className={styles.help}
              help={help.find((h) => h.tag === 'dashboard_create_campaign')}
            >
              <p>
                {help.find((h) => h.tag === 'dashboard_create_campaign')?.text}
              </p>
            </HelpModal>
          )}
        </div>
      </Card>

      <Card cardType={'orange-circle'}>
        <h3 className={'subtitle-1-orange'}>
          Ambassadors
          <Link
            className={clsx(
              styles.card__view_link,
              'caption-1-hyperlink-orange'
            )}
            to={{
              pathname: `${PATH.AMBASSADORS}`,
              search: `?status=Joined`,
            }}
          >
            View
          </Link>
        </h3>
        <div className={styles.card__data}>
          <div className={styles.card__data_item}>
            <span className={styles.card__data_label}>Invited</span>
            <span className={styles.card__data_value}>{totalInvited}</span>
          </div>

          <div className={styles.card__data_item}>
            <span className={styles.card__data_label}>Accepted</span>
            <span className={styles.card__data_value}>{totalJoined}</span>
          </div>
          <div className={clsx(styles.card__data_percent, 'subtitle-1-orange')}>
            {totalInvited > 0
              ? ((totalJoined / totalInvited) * 100).toFixed(0)
              : 0}
            %
          </div>
        </div>
      </Card>

      <Card cardType={'green-circle'}>
        <h3 className={'subtitle-1-green'}>
          Prospects
          <span
            className={clsx(
              styles.card__view_link,
              'caption-1-hyperlink-orange'
            )}
            onClick={() => {
              navigate({ pathname: `${PATH.PROSPECTS}` })
            }}
          >
            View
          </span>
        </h3>
        <div className={styles.card__data}>
          <div className={styles.card__data_item}>
            <span className={styles.card__data_label}>Referred</span>
            <span className={styles.card__data_value}>{totalReferred}</span>
          </div>

          <div className={styles.card__data_item}>
            <span className={styles.card__data_label}>Registered</span>
            <span className={styles.card__data_value}>{totalRegistered}</span>
          </div>
        </div>

        <div className={clsx(styles.card__data_percent, 'subtitle-1-green')}>
          {totalReferred
            ? ((totalRegistered / totalReferred) * 100).toFixed(0)
            : 0}
          %
        </div>
      </Card>

      {/* <!-- Row 3 --> */}
      <div className={styles.page__large_panel}>
        <h3
          className={clsx('headline-6-green', styles.page__large_panel__title)}
        >
          Campaigns{' '}
          <span
            className={clsx(
              styles.card__view_link,
              'subtitle-2-orange',
              'hyperlink'
            )}
            onClick={() => {
              handleStatusFilterChange('All Campaigns')
            }}
          >
            View All
          </span>
        </h3>
        <DataTable
          columns={columns}
          data={summaries}
          noDataComponent=""
          pagination
          customStyles={{
            rows: {
              style: {
                backgroundColor: '#f8f8f8',
              },
            },
            headCells: {
              style: {
                backgroundColor: '#f8f8f8',
                padding: '0px 16px',
                paddingTop: 0,
                fontFamily: 'Rubik-Regular',
                fontWeight: 400,
                letterSpacing: '0.4px',
                fontSize: '1.2rem',
                lineHeight: '1.6rem',
                color: '#808080',
                textAlign: 'center',
              },
            },
            cells: {
              style: {
                textAlign: 'center',
              },
            },
          }}
        />
      </div>

      {/* <!-- Row 4 --> */}
      <div className={[styles.page__large_panel, styles.hide]}>
        <h3
          className={clsx(
            'headline-6-green',
            styles.page__large_panel__title,
            styles.hide
          )}
        >
          Recent Activity
        </h3>
      </div>
    </div>
  )
}

export default Dashboard
