import React, { useEffect, useState } from 'react'
import { CSVDownload } from 'react-csv'
import styles from './Performance.module.scss'
import clsx from 'clsx'
import { PrimaryButton, SecondaryButton, CSVIcon, HelpModal } from 'components'
import { useStateValue } from 'state'
import _ from 'lodash'
import { useNavigate } from 'hooks'
import {
  PATH,
  PERFORMANCE_METRICS,
  PERFORMANCE_METRICS_ORDER,
} from 'utils/constants'
import { toast } from 'react-toastify'

const Performance = ({
  campaignId,
  onNext = () => null,
  activeCampaign,
  hideWarning,
}) => {
  const {
    action: { getCampaignPerformance, getPerformanceReport },
    state: { help },
  } = useStateValue()
  const [performance, setPerformance] = useState({
    prospect: {},
    ambassador: {},
    referrals: 0,
    registrations: 0,
    events: [],
  })
  const [performanceEvents, setPerformanceEvents] = useState([])
  const [totalEvent, setTotalEvent] = useState({
    processed: 0,
    delivered: 0,
    open: 0,
    click: 0,
    bounce: 0,
    dropped: 0,
  })
  // const [rewardSummary, setRewardSummary] = useState({
  //   referrals: [],
  //   ambassadorRewards: [],
  //   prospectRewards: [],
  // })
  const [performanceSummary, setPerformanceSummary] = useState([])

  const [reportBtnDisabled, setRptBtnDisabled] = useState(false)
  const [csvDownloadReady, setCsvDownloadReady] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (activeCampaign && !hideWarning)
      toast.warning(
        'This campaign is active. Any edits may impact your terms or the campaign outcomes.',
        1500
      )
  }, [activeCampaign, hideWarning])

  useEffect(
    () =>
      getCampaignPerformance(campaignId, (performance) => {
        const totalEvent = {
          processed: 0,
          delivered: 0,
          open: 0,
          click: 0,
          bounce: 0,
          dropped: 0,
        }
        performance.events.forEach((event) =>
          Object.keys(event).forEach(
            (key) => (totalEvent[key] += parseInt(event[key] || '0'))
          )
        )
        let performanceEvents = []
        performance.events.forEach((m) => {
          const index = _.findIndex(performanceEvents, function (obj) {
            return obj.messageType === m.messageType
          })
          if (index !== -1) {
            performanceEvents[index][m.eventType] = m.count
          } else {
            let temp = {}
            temp.messageType = m.messageType
            temp[m.eventType] = m.count
            performanceEvents.push(temp)
          }
        })
        _.reduce(
          performanceEvents,
          function (res, value, key) {
            if (value.processed)
              totalEvent.processed += parseInt(value.processed)
            if (value.delivered)
              totalEvent.delivered += parseInt(value.delivered)
            if (value.open) totalEvent.open += parseInt(value.open)
            if (value.click) totalEvent.click += parseInt(value.click)
            if (value.bounce) totalEvent.bounce += parseInt(value.bounce)
            if (value.dropped) totalEvent.dropped += parseInt(value.dropped)
          },
          0
        )
        setPerformance(performance)
        performanceEvents.sort(
          (a, b) =>
            PERFORMANCE_METRICS_ORDER.indexOf(a.messageType) -
            PERFORMANCE_METRICS_ORDER.indexOf(b.messageType)
        )
        setPerformanceEvents(performanceEvents)
        setTotalEvent(totalEvent)
      }),
    // eslint-disable-next-line
    [campaignId]
  )

  // const csvReportClick = (e) => {
  //   e.preventDefault()
  //   setRptBtnDisabled(true)
  //   setCsvDownloadReady(false)

  //   getAmbassadorRewardSummaries(campaignId, (summary) => {
  //     summary.referrals.forEach((ref) => {
  //       ref['amb-fname'] = ref.Person.firstName
  //       ref['amb-lname'] = ref.Person.lastName
  //       ref['amb-email'] = ref.Person.email
  //       ref['amb-phone'] = ref.Person.phone
  //       delete ref.Person
  //       summary.ambassadorRewards.forEach((amb, idx) => {
  //         const num = idx + 1
  //         ref[`amb-reward-${num}-type`] = amb.type
  //         ref[`amb-reward-${num}-desc`] = amb.desc
  //         ref[`amb-reward-${num}-amt`] = amb.amount
  //       })
  //       summary.prospectRewards.forEach((rwd, idx) => {
  //         const num = idx + 1
  //         ref[`prospect-reward-${num}-type`] = rwd.type
  //         ref[`prospect-reward-${num}-desc`] = rwd.desc
  //         ref[`prospect-reward-${num}-amt`] = rwd.amount
  //       })
  //     })
  //     // console.log(summary.referrals)
  //     setRewardSummary(summary)
  //     setRptBtnDisabled(false)
  //     setCsvDownloadReady(true)
  //   })
  // }

  const csvReportClick = (e) => {
    e.preventDefault()
    setRptBtnDisabled(true)
    setCsvDownloadReady(false)

    getPerformanceReport(campaignId, (report) => {
      setPerformanceSummary(report)
      setRptBtnDisabled(false)
      setCsvDownloadReady(true)
    })
  }

  return (
    <div className={styles.section}>
      <div className={styles.profile_container}>
        <h4 className={styles.section__title}>
          Performance <br />
        </h4>
        {help.find((h) => h.tag === 'performance_overview') && (
          <HelpModal
            className={styles.helpModal__top}
            help={help.find((h) => h.tag === 'performance_overview')}
          >
            <p>{help.find((h) => h.tag === 'performance_overview')?.text}</p>
          </HelpModal>
        )}
      </div>
      <div className={styles.section__header}>
        <div className={styles.ambassador_landing_page}>
          <div className={clsx(styles.block, styles['block--green'])}>
            <div
              className={clsx(
                styles.block__title,
                styles['block__title--green']
              )}
            >
              Ambassador Landing Page
              {help.find(
                (h) => h.tag === 'performance_ambassador_ldg_page'
              ) && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find(
                    (h) => h.tag === 'performance_ambassador_ldg_page'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'performance_ambassador_ldg_page'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>

            <div className={styles.ambassador_landing_page__row}>
              <div className={styles.ambassador_landing_page__col}>
                <div className={styles.prospect_landing_page__label}>
                  Private LP Views
                  {help.find(
                    (h) => h.tag === 'performance_ambassador_ldg_private'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_ambassador_ldg_private'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) =>
                              h.tag === 'performance_ambassador_ldg_private'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <h6>{performance.ambassador.privateLinkViews || 0}</h6>
              </div>

              <div className={styles.ambassador_landing_page__col}>
                <div className={styles.ambassador_landing_page__label}>
                  Public LP Views
                  {help.find(
                    (h) => h.tag === 'performance_ambassador_ldg_public'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_ambassador_ldg_public'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'performance_ambassador_ldg_public'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <h6>{performance.ambassador.publicLinkViews}</h6>
              </div>

              <div className={styles.ambassador_landing_page__col}>
                <div className={styles.ambassador_landing_page__label}>
                  Joined
                  <br />
                  &nbsp;
                  {help.find(
                    (h) => h.tag === 'performance_ambassador_ldg_joined'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_ambassador_ldg_joined'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'performance_ambassador_ldg_joined'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <h6>{performance.ambassador.joined}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.prospect_landing_page}>
          <div className={clsx(styles.block, styles['block--green'])}>
            <div
              className={clsx(
                styles.block__title,
                styles['block__title--green']
              )}
            >
              Prospect Landing Page
              {help.find((h) => h.tag === 'performance_prospect_ldg_page') && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find(
                    (h) => h.tag === 'performance_prospect_ldg_page'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'performance_prospect_ldg_page'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>

            <div className={styles.prospect_landing_page__row}>
              <div className={styles.prospect_landing_page__col}>
                <div className={styles.prospect_landing_page__label}>
                  UL Views
                  {help.find(
                    (h) => h.tag === 'performance_prospect_ldg_ul'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_prospect_ldg_ul'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'performance_prospect_ldg_ul'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <h6>{performance.prospect.sharePageViews || 0}</h6>
              </div>

              <div className={styles.prospect_landing_page__col}>
                <div className={styles.prospect_landing_page__label}>
                  Learn More (LM)
                  {help.find(
                    (h) => h.tag === 'performance_prospect_ldg_lm'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_prospect_ldg_lm'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'performance_prospect_ldg_lm'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                  <br />
                  &nbsp;
                </div>
                <h6>{performance.prospect.inquiries}</h6>
              </div>

              <div className={styles.prospect_landing_page__col}>
                <div className={styles.prospect_landing_page__label}>
                  Claim Code (CC)
                  {help.find(
                    (h) => h.tag === 'performance_prospect_ldg_cc'
                  ) && (
                    <HelpModal
                      className={styles.helpModal}
                      help={help.find(
                        (h) => h.tag === 'performance_prospect_ldg_cc'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'performance_prospect_ldg_cc'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <h6>{performance.prospect.codesClaimed}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.referrals}>
          <div className={clsx(styles.block, styles['block--red'])}>
            <div
              className={clsx(styles.block__title, styles['block__title--red'])}
            >
              Referrals
              {help.find((h) => h.tag === 'performance_referrals') && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find((h) => h.tag === 'performance_referrals')}
                >
                  <p>
                    {help.find((h) => h.tag === 'performance_referrals')?.text}
                  </p>
                </HelpModal>
              )}
            </div>
            <h6>{performance.referrals}</h6>
          </div>
        </div>

        <div className={styles.registrations}>
          <div className={clsx(styles.block, styles['block--blue'])}>
            <div
              className={clsx(
                styles.block__title,
                styles['block__title--blue']
              )}
            >
              Registrations
              {help.find((h) => h.tag === 'performance_registrations') && (
                <HelpModal
                  className={styles.helpModal}
                  help={help.find((h) => h.tag === 'performance_registrations')}
                >
                  <p>
                    {
                      help.find((h) => h.tag === 'performance_registrations')
                        ?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            <h6>{performance.registrations || '0'}</h6>
          </div>
        </div>
      </div>

      <div className={styles.section__main}>
        <div className={styles.metrics__header}>
          <div className={styles.metrics__title}>
            Email Metrics
            {help.find((h) => h.tag === 'performance_email_metrics') && (
              <HelpModal
                className={styles.helpModal__metrics}
                help={help.find((h) => h.tag === 'performance_email_metrics')}
              >
                <p>
                  {
                    help.find((h) => h.tag === 'performance_email_metrics')
                      ?.text
                  }
                </p>
              </HelpModal>
            )}
          </div>

          <SecondaryButton
            className={styles.button__download}
            onClick={csvReportClick}
            disabled={reportBtnDisabled}
          >
            <CSVIcon className={styles.button__download__icon} /> Download
            Report
            {csvDownloadReady && (
              <CSVDownload data={performanceSummary.report} target="_blank" />
            )}
          </SecondaryButton>
        </div>

        <table className={styles.metrics__table}>
          <thead>
            <tr>
              {[
                'Event',
                'Processed',
                'Delivered',
                'Opened',
                'Clicked',
                'Bounced',
                'Dropped',
              ].map((key) => (
                <th
                  key={key}
                  className={clsx(
                    styles.metrics__th,
                    styles[`metrics__th__${key.toLowerCase()}`]
                  )}
                >
                  {key}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {performanceEvents.map(
              (
                {
                  messageType,
                  processed,
                  delivered,
                  open,
                  click,
                  bounce,
                  dropped,
                },
                key
              ) => (
                <tr className={styles.metrics__tr} key={key}>
                  <td className={styles.metrics__td}>
                    {PERFORMANCE_METRICS[messageType]}
                  </td>
                  <td className={styles.metrics__td}>{processed || '-'}</td>
                  <td className={styles.metrics__td}>{delivered || '-'}</td>
                  <td className={styles.metrics__td}>{open || '-'}</td>
                  <td className={styles.metrics__td}>{click || '-'}</td>
                  <td className={styles.metrics__td}>{bounce || '-'}</td>
                  <td className={styles.metrics__td}>{dropped || '-'}</td>
                </tr>
              )
            )}

            <tr>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                Total
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.processed}
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.delivered}
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.open}
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.click}
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.bounce}
              </td>
              <td
                className={clsx(styles.metrics__td, styles.metrics__td__total)}
              >
                {totalEvent.dropped}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.section__footer}>
        <PrimaryButton
          className={styles.button__next}
          label="Next"
          onClick={onNext}
        />

        <SecondaryButton
          label="Close"
          onClick={() => navigate({ pathname: PATH.CAMPAIGNS })}
        />
      </div>
    </div>
  )
}

export default Performance
