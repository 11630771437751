import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import styles from './AddressInput.module.scss'
import { loadScript } from 'hooks'
import _ from 'lodash'
import { HelpModal } from 'components'

const AddressInput = ({
  classNames = { container: '', label: '', input: '' },
  label = null,
  placeholder = '',
  value: initValue = '',
  captionText = null,
  requiredShown = false,
  optionalShown = false,
  isError = false,
  readOnly = false,
  onChange: handleChangeValue = () => null,
  help = false,
  helpChildren = <></>,
}) => {
  const { REACT_APP_GOOGLE_API_KEY } = process.env
  const autoCompleteRef = useRef(null)
  const autoComplete = useRef(null)
  const [query, setQuery] = useState('')

  useEffect(() => setQuery(initValue), [initValue])

  const handlePlaceSelect = (updateQuery) => {
    const addressObject = autoComplete.current.getPlace()
    try {
      if (addressObject) {
        const address_1 = addressObject.address_components
          .filter(
            ({ types }) =>
              _.isEqual(types, ['street_number']) || _.isEqual(types, ['route'])
          )
          .map(({ short_name }) => short_name)
          .join(' ')

        const city = addressObject.address_components
          .filter(({ types }) => _.isEqual(types, ['locality', 'political']))
          .map(({ short_name }) => short_name)
          .join(' ')

        const state = addressObject.address_components
          .filter(({ types }) =>
            _.isEqual(types, ['administrative_area_level_1', 'political'])
          )
          .map(({ short_name }) => short_name)
          .join(' ')

        const zipCode = addressObject.address_components
          .filter(({ types }) => _.isEqual(types, ['postal_code']))
          .map(({ short_name }) => short_name)
          .join(' ')

        const country = addressObject.address_components
          .filter(({ types }) => _.isEqual(types, ['country', 'political']))
          .map(({ short_name }) => short_name)
          .join(' ')

        updateQuery(`${address_1}, ${city}, ${state} ${zipCode}, ${country}`)

        handleChangeValue({ address_1, city, state, zipCode, country })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => {
        autoComplete.current = new window.google.maps.places.Autocomplete(
          autoCompleteRef.current
        )
        autoComplete.current.setFields(['address_components'])
        autoComplete.current.addListener('place_changed', () =>
          handlePlaceSelect(setQuery)
        )
      },
      { id: 'google-places' }
    )
    // eslint-disable-next-line
  }, [])

  return (
    <div className={clsx(classNames.container, styles.address_input__wrapper)}>
      {label ? (
        <div className={styles.address_input__wrapper__dis}>
        <label className={clsx(classNames.label, styles.address_input__label)}>
          {label === ' ' ? <>&nbsp;</> : label}
          {requiredShown ? (
            <span className={styles.address_input__required}>&nbsp;*</span>
          ) : null}
          {optionalShown ? (
            <span className={styles.address_input__optional}>
              &nbsp;(optional)
            </span>
          ) : null}
        </label>
        {help && <HelpModal help={help}>{helpChildren}</HelpModal>}
        </div>
      ) : null}

      <input
        ref={autoCompleteRef}
        className={clsx(
          classNames.input,
          styles.address_input,
          isError && styles[`address_input--error`]
        )}
        placeholder={placeholder}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        readOnly={readOnly}
      />

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>
    </div>
  )
}

export default AddressInput
