import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LogoIcon, TextInput, PrimaryButton, Illustration1 } from 'components'
import styles from './SignUp.module.scss'
import { useStateValue } from 'state'
import { useNavigate } from 'hooks'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { PATH } from 'utils/constants'
import { toast } from 'react-toastify'

const SignUp = () => {
  const navigate = useNavigate()
  const {
    state: { deviceSize },
    action: { userSignUp },
  } = useStateValue()

  const [acctCreated, setAcctCreated] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Please enter your email.')
        .email('Please enter a valid email.'),
    }),
    onSubmit: (formValues) => {
      userSignUp(formValues, (message, code) => {
        // console.log('navigating to: ', PATH.CAMPS)
        // TODO Show  a div telling the user to check email.
        toast.info(message)
        if (code === 201) setAcctCreated(true)
        else if (code === 409) navigate({ pathname: PATH.AUTH__SIGN_IN })
      })
    },
  })

  return (
    <div className={styles.page__container}>
      <div className={styles.page__wrapper}>
        <div className={styles.page}>
          <Link className={styles.logo} to="/">
            <LogoIcon />
          </Link>
          {['xs', 'sm', 'md'].includes(deviceSize) ? (
            <h4 className={styles.page__title} data-cy="signUpPageTitle">
              Welcome to Camp Tree!
              <br />
              <br />{' '}
              {acctCreated
                ? 'Verify your email.'
                : 'Please create your new account to start your 14-day free trial.'}
            </h4>
          ) : (
            <h5 className={styles.page__title} data-cy="signUpPageTitle">
              Welcome to Camp Tree!
              <br />
              <br />{' '}
              {acctCreated
                ? 'Verify your email.'
                : 'Please create your new account to start your 14-day free trial.'}
            </h5>
          )}

          {acctCreated ? (
            <div className={styles.acct_created}>
              Please check your email for a verification link.
            </div>
          ) : (
            <form data-cy="sign-up-form">
              <TextInput
                label="Enter your email:"
                value={formik.values.email}
                dataCy="emailInput"
                onChange={(email) => formik.setFieldValue('email', email)}
                {...(formik.touched.email && formik.errors.email
                  ? { isError: true, captionText: formik.errors.email }
                  : {})}
              />

              {/* {error && <div className={styles.error}>{error.message}</div>} */}

              <div className={styles.buttons__container}>
                <PrimaryButton
                  className={styles.button__sign_up}
                  label="CONFIRM"
                  type="submit"
                  onClick={formik.handleSubmit}
                />
              </div>
              <div className={styles.options}>
                <Link
                  className={styles.login_link}
                  to="/sign-in"
                  dangerouslySetInnerHTML={{
                    __html: 'Already have an account?',
                  }}
                />
              </div>
            </form>
          )}
        </div>

        {deviceSize === 'xxl' ? (
          <Illustration1 className={styles.page__illustration} />
        ) : null}
      </div>
    </div>
  )
}

export default SignUp
