import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import styles from './Select.module.scss'
import { ChevronArrowIcon, HelpModal } from 'components'
import { useClickOutside } from 'hooks'

const Select = ({
  classNames = { container: '', label: '', select: '' },
  label = null,
  placeholder = '',
  captionText = null,
  options: initOptions = [],
  value: initValue = '',
  requiredShown = false,
  optionalShown = false,
  isError = false,
  disabled = false,
  onChange: handleChangeOption = () => null,
  help = false,
  helpChildren = <></>,
}) => {
  const [isOpened, setOpened] = useState(false)
  const [value, setValue] = useState(initValue)
  const selectRef = useRef(null)

  useClickOutside(selectRef, () => setOpened(false))

  // eslint-disable-next-line
  useEffect(() => value !== initValue && setValue(initValue), [initValue])

  const handleClickSelect = () =>
    !disabled && initOptions.length ? setOpened(!isOpened) : null

  const handleReset = () => {
    setValue('')
    handleChangeOption('')
  }

  const handleClickOption = (value) => {
    setOpened(false)
    setValue(value)
    handleChangeOption(value)
  }

  return (
    <div className={clsx(classNames.container, styles.select__wrapper)}>
      {label ? (
        <div className={styles.select__wrapper__dis}>
          <label className={clsx(classNames.label, styles.select__label)}>
            {label === ' ' ? <>&nbsp;</> : label}
            {requiredShown ? (
              <span className={styles.select__required}>&nbsp;*</span>
            ) : null}
            {optionalShown ? (
              <span className={styles.text_input__optional}>
                &nbsp;(optional)
              </span>
            ) : null}
          </label>
          {help && <HelpModal help={help}>{helpChildren}</HelpModal>}
        </div>
      ) : null}

      <div
        className={clsx(
          classNames.select,
          styles.select,
          isOpened && styles[`select--opened`],
          disabled && styles[`select--disabled`],
          isError && styles[`select--error`]
        )}
        ref={selectRef}
        onClick={handleClickSelect}
      >
        <div className={styles.select__value}>
          <span className={clsx(!value && styles.select__placeholder)}>
            {!isOpened && (value || placeholder)}
          </span>
          <ChevronArrowIcon
            className={clsx(
              styles.select__arrow,
              disabled && styles[`select__arrow--disabled`],
              isOpened && styles['select__arrow--opened']
            )}
          />
        </div>

        <div
          className={clsx(
            styles.select__options__wrapper,
            isOpened && styles[`select__options__wrapper--opened`]
          )}
          style={{ maxHeight: isOpened && 24 * (initOptions.length + 1) + 20 }}
        >
          <div className={styles.select__option} onClick={handleReset}>
            <span className={styles.select__placeholder}>{placeholder}</span>
          </div>

          {initOptions.map((option, oIndex) => (
            <div
              key={oIndex}
              className={clsx(
                styles.select__option,
                option === value && styles['select__option--selected']
              )}
              onClick={() => handleClickOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>

      <p className={clsx(styles.caption, isError && styles.caption__error)}>
        {captionText ? <span>{captionText}</span> : null}
      </p>
    </div>
  )
}

export default Select
