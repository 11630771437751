import React, { useState, useEffect } from 'react'
import styles from './ProspectList.module.scss'
import {
  TextInput,
  IllustrationSearch,
  MagnifierIcon,
  SelectFilter,
  HelpModal,
} from 'components'

import clsx from 'clsx'
import { STORAGE_NAME, PATH, REFERRAL_TYPE } from 'utils/constants'
import { getStorageItem } from 'utils'
import { useNavigate } from 'hooks'
import { useStateValue } from 'state'
import { useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import _ from 'lodash'
import moment from 'moment'

const ProspectList = () => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [people, setPeople] = useState([])
  const [allPeople, setAllPeople] = useState([])
  const [searchText, setSearchText] = useState('')

  const [selectedCampaign, setSelectedCampaign] = useState({
    key: 'all',
    name: 'All Campaigns',
    id: null,
  })

  const [campaigns, setCampaigns] = useState([])

  const navigate = useNavigate()
  const {
    action: { getCampReferrals, getCampaignSummaries },
    state: { help },
  } = useStateValue()

  const query = new URLSearchParams(useLocation().search)

  // on load fetch all campaigns
  useEffect(() => {
    if (campIdInUse) {
      getCampaignSummaries(campIdInUse, 'ALL', (summaries) => {
        summaries = summaries.filter((summary) => !summary.testMode)
        summaries.unshift({ key: 'all', name: 'All Campaigns', id: null })
        setCampaigns(summaries)
      })
      getCampReferrals(campIdInUse, (people) => {
        if (query.get('campaign')) {
          let filtered_people = _.filter(
            people,
            (p) => p.Campaign.id === query.get('campaign')
          )
          if (query.get('status')) {
            switch (query.get('status')) {
              case 'registered':
                filtered_people = _.filter(
                  filtered_people,
                  (p) => p.registeredAt !== null
                )
                break
              case 'promocode':
                filtered_people = _.filter(
                  filtered_people,
                  (p) => p.type === REFERRAL_TYPE.REGISTRATION
                )
                break
              case 'inquiry':
                filtered_people = _.filter(
                  filtered_people,
                  (p) => p.type === REFERRAL_TYPE.INQUIRY
                )
                break
              default:
                filtered_people = _.filter(
                  filtered_people,
                  (p) => p.registeredAt !== null
                )
            }
          }

          setPeople(filtered_people)
          setAllPeople(people)
          return
        }
        setPeople(people)
        setAllPeople(people)
      })
    }
  }, [campIdInUse])

  useEffect(() => {
    if (!campIdInUse) {
      navigate({ pathname: PATH.CAMPS })
      return
    }
  }, [campIdInUse, navigate])

  // setting campaign and status as per url params
  useEffect(() => {
    if (!query.get('campaign'))
      setSelectedCampaign({
        key: 'all',
        name: 'All Campaigns',
        id: null,
      })
    else if (query.get('campaign') && campaigns.length > 0)
      setSelectedCampaign(
        _.find(campaigns, (campaign) => campaign.id === query.get('campaign'))
      )
  }, [query.get('campaign'), campaigns])

  const handleCampaignFilter = (obj) => {
    setSelectedCampaign(obj)
    setSearchText('')
    if (obj.key === 'all') {
      setPeople(allPeople)
      return
    }
    let filtered_people = _.filter(allPeople, (p) => p.Campaign.id === obj.id)
    setPeople(filtered_people)
  }

  const filterAmbassadorsBySearch = (searchText) => {
    if (searchText === '') {
      setPeople(allPeople)
      return
    }
    searchText = searchText.trim()
    setSearchText(searchText)
    let filteredPeople = _.filter(
      allPeople,
      (p) =>
        p?.firstName.match(new RegExp(searchText, 'i')) ||
        p?.lastName.match(new RegExp(searchText, 'i')) ||
        p?.email.match(new RegExp(searchText, 'i')) ||
        p?.Person?.firstName.match(new RegExp(searchText, 'i')) ||
        p?.Campaign?.name.match(new RegExp(searchText, 'i')) ||
        p?.Person?.lastName.match(new RegExp(searchText, 'i')) ||
        p?.type.toLowerCase().match(new RegExp(searchText, 'i')) ||
        p?.phone.match(new RegExp(searchText, 'i'))
    )
    setPeople(filteredPeople)
  }

  // table columns

  const columns = [
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Name
        </div>
      ),
      selector: (referral) => referral.firstName + ' ' + referral.lastName,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__status
          )}
        >
          Email
        </div>
      ),
      selector: (referral) => referral.email,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Phone
        </div>
      ),
      selector: (referral) => referral.phone,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Campaign
        </div>
      ),
      selector: (referral) =>
        selectedCampaign.id === null
          ? referral?.Campaign?.name
          : selectedCampaign.name,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Ambassador
        </div>
      ),
      selector: (referral) =>
        referral.Person.firstName + ' ' + referral.Person.lastName,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Type
        </div>
      ),
      selector: (referral) =>
        referral.type === REFERRAL_TYPE.INQUIRY ? 'Learn More' : 'Claim Code',
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Referral Date
        </div>
      ),
      selector: (referral) => moment(referral.created_at).format('MM/DD/YYYY'),
      sortable: true,
    },
    {
      name: (
        <div
          key={'Registrations'}
          className={clsx(
            styles.metrics__th,
            'Approve Registration' &&
              styles[
                `metrics__th__${'Approve Registration'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Registration
        </div>
      ),
      sortable: true,
      selector: (referral) => referral.registrations,
    },
    {
      name: (
        <div
          key={'Approve Registration'}
          className={clsx(
            styles.metrics__th,
            'Approve Registration' &&
              styles[
                `metrics__th__${'Approve Registration'
                  .replaceAll(' - ', '_')
                  .replaceAll(' ', '_')
                  .toLowerCase()}`
              ]
          )}
        >
          Approved
        </div>
      ),
      sortable: true,
      selector: (referral) => (!!referral.registeredAt ? 'Yes' : 'No'),
    },
  ]

  return (
    <div className={styles.page}>
      <div className={styles.search__wrapper}>
        <div className={styles.search__title}>
          Prospect
          {help.find((h) => h.tag === 'prospects_search_overview') && (
            <HelpModal
              className={styles.helpModal}
              help={help.find((h) => h.tag === 'prospects_search_overview')}
            >
              <p>
                {help.find((h) => h.tag === 'prospects_search_overview')?.text}
              </p>
            </HelpModal>
          )}
        </div>

        <div className={styles.search__form}>
          <TextInput
            classNames={{
              container: styles.search__input__wrapper,
              input: styles.search__input,
            }}
            value={searchText}
            onChange={(keyword) => {
              filterAmbassadorsBySearch(keyword)
            }}
          />
          <MagnifierIcon className={styles.search__icon} />
        </div>
      </div>

      <div className={styles.list__wrapper}>
        <div className={styles.list__header}>
          <SelectFilter
            filters={campaigns}
            showKey="name"
            uniqueKey="id"
            className={styles.filter_button}
            handleFilterClick={handleCampaignFilter}
            selectedStatus={selectedCampaign}
          />
        </div>

        <div
          className={clsx(
            styles.list__view,
            !people.length && styles['list__view--empty']
          )}
        >
          {people.length ? (
            <DataTable
              columns={columns}
              data={people}
              pagination
              onRowClicked={(e) => {
                navigate({
                  pathname: `${PATH.PROSPECTS__EDIT}/${e.id}`,
                })
              }}
              customStyles={{
                cells: {
                  style: {
                    cursor: 'pointer',
                  },
                },
              }}
            />
          ) : (
            <IllustrationSearch />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProspectList
