import { useNavigate } from 'hooks'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { useStateValue } from 'state'
import { STORAGE_NAME, PATH, CAMPAIGN_STATUS } from 'utils/constants'
import { getStorageItem } from 'utils'
import clsx from 'clsx'
import {
  Breadcrumb,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from 'components'
import styles from './AmbassadorEdit.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { toast } from 'react-toastify'

const LINK_TYPE = {
  UNIQUE: 'LINK__UNIQUE',
  DASHBOARD: 'LINK__DAHSBOARD',
}

const AmbassadorEdit = () => {
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const [person, setPerson] = useState()
  const [totalReferrals, setTotalReferrals] = useState(0)
  const [totalRegistrations, setTotalRegistrations] = useState(0)
  const { id } = useParams()
  const { state } = useLocation()
  const {
    action: { getCampPerson, updateCampPerson },
  } = useStateValue()
  const navigate = useNavigate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      ...person,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required.'),
      lastName: Yup.string().required('Last Name is required.'),
      email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.'),
      phone: Yup.string()
        .matches(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          'Please enter a valid phone number.'
        )
        .nullable(),
    }),
    onSubmit: ({ firstName, lastName, email, phone }) =>
      updateCampPerson(
        campIdInUse,
        id,
        { firstName, lastName, email, phone },
        () => {
          setPerson({ ...person, firstName, lastName, email, phone })
          toast.success('Ambassador saved successfully.')
        }
      ),
  })

  useEffect(() => {
    if (!campIdInUse) navigate({ pathname: PATH.CAMPS })
    else if (!id) navigate({ pathname: PATH.CUSTOMERS })
    else
      getCampPerson(campIdInUse, id, ['Referrals', 'Campaigns'], (person) => {
        setPerson(person)
        setTotalReferrals(person.Referrals.length)
        setTotalRegistrations(
          person.Referrals.filter(({ registeredAt }) => registeredAt).length
        )
      })

    // eslint-disable-next-line
  }, [])

  const getFullName = () => `${person.firstName} ${person.lastName}`

  const getCampaignReferrals = (id) => {
    try {
      return person.Referrals.filter(({ campaignId }) => campaignId === id)
        .length
    } catch (error) {
      return 0
    }
  }

  const getCampaignRegistrations = (id) => {
    try {
      return person.Referrals.filter(
        ({ campaignId, registeredAt }) => registeredAt && campaignId === id
      ).length
    } catch (error) {
      return 0
    }
  }

  const handleClickCopy = (uniqueLink, type = LINK_TYPE.UNIQUE) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_SHARE_HOST}${
        type === LINK_TYPE.UNIQUE ? '/p/' : '/a/d/'
      }${uniqueLink}`
    )

    toast.info(
      `${type === LINK_TYPE.UNIQUE ? 'Unique' : 'Dashboard'} Link is copied.`
    )
  }

  if (!person) return <React.Fragment />

  return (
    <>
      <Breadcrumb
        items={[
          { to: PATH.AMBASSADORS, text: 'Ambassadors' },
          { to: `${PATH.AMBASSADORS__EDIT}/${person.id}`, text: getFullName() },
        ]}
      />

      <div className={styles.page}>
        <div className={styles.page__header}>
          <h4 className={styles.text__person__name}>{getFullName()}</h4>

          <span className={styles.text__person__type}>
            {person.type.toLowerCase()}
          </span>
        </div>

        <div className={styles.section}>
          <div className={styles.section__title}>Contact Info</div>

          <div className={styles.form}>
            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={(firstName) =>
                    formik.setFieldValue('firstName', firstName)
                  }
                  {...(formik.touched.firstName && formik.errors.firstName
                    ? { isError: true, captionText: formik.errors.firstName }
                    : {})}
                />
              </div>

              <div className={styles.form__col}>
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={(lastName) =>
                    formik.setFieldValue('lastName', lastName)
                  }
                  {...(formik.touched.lastName && formik.errors.lastName
                    ? { isError: true, captionText: formik.errors.lastName }
                    : {})}
                />
              </div>
            </div>

            <div className={styles.form__row}>
              <div className={styles.form__col}>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={(email) => formik.setFieldValue('email', email)}
                  {...(formik.touched.email && formik.errors.email
                    ? { isError: true, captionText: formik.errors.email }
                    : {})}
                />
              </div>

              <div className={styles.form__col}>
                <TextInput
                  label="Phone"
                  placeholder="Phone"
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue('phone', phone)}
                  {...(formik.touched.phone && formik.errors.phone
                    ? { isError: true, captionText: formik.errors.phone }
                    : {})}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.ambassador_info__wrapper}>
          <div className={styles.ambassador_info__title}>Ambassador Info</div>

          <table>
            <thead>
              <tr>
                <th className={styles.ambassador_info__th}>Campaigns Joined</th>
                <th
                  className={clsx(
                    styles.ambassador_info__th,
                    styles.ambassador_info__th__wrap
                  )}
                >
                  Campaign Dates
                </th>
                <th className={styles.ambassador_info__th}>Referrals</th>
                <th className={styles.ambassador_info__th}>Registrations</th>
                <th
                  className={clsx(
                    styles.ambassador_info__th,
                    styles.ambassador_info__th__unique_link
                  )}
                >
                  Unique Link
                </th>
                <th
                  className={clsx(
                    styles.ambassador_info__th,
                    styles.ambassador_info__th__dashboard_link
                  )}
                >
                  Dashboard Link
                </th>
              </tr>
            </thead>

            <tbody>
              {person.Campaigns.map(
                (
                  {
                    id,
                    name,
                    startDate,
                    endDate,
                    CampaignAmbassador: { uniqueLink },
                    status,
                  },
                  cIdx
                ) => (
                  <tr key={cIdx}>
                    <td
                      className={clsx(
                        styles.ambassador_info__td,
                        styles.ambassador_info__td__name
                      )}
                    >
                      <Link
                        to={
                          [
                            CAMPAIGN_STATUS.ACTIVE,
                            CAMPAIGN_STATUS.CLOSED,
                          ].includes(status)
                            ? `${PATH.CAMPAIGNS__EDIT}/performance/${id}`
                            : `${PATH.CAMPAIGNS__EDIT}/define/${id}`
                        }
                      >
                        {name}
                      </Link>
                    </td>
                    <td
                      className={clsx(
                        styles.ambassador_info__td,
                        styles.ambassador_info__td__start_date
                      )}
                    >
                      {moment(startDate).format('M/D/YY')} -{' '}
                      {moment(endDate).format('M/D/YY')}
                    </td>
                    <td className={styles.ambassador_info__td}>
                      {getCampaignReferrals(id)}
                    </td>
                    <td className={styles.ambassador_info__td}>
                      {getCampaignRegistrations(id)}
                    </td>
                    <td className={styles.ambassador_info__td}>
                      <button
                        className={clsx(styles.copy, styles.copy__unique_link)}
                        onClick={() =>
                          handleClickCopy(uniqueLink, LINK_TYPE.UNIQUE)
                        }
                      >
                        Copy
                      </button>
                    </td>
                    <td className={styles.ambassador_info__td}>
                      <button
                        className={clsx(
                          styles.copy,
                          styles.copy__dashboard_link
                        )}
                        onClick={() =>
                          handleClickCopy(uniqueLink, LINK_TYPE.DASHBOARD)
                        }
                      >
                        Copy
                      </button>
                    </td>
                  </tr>
                )
              )}

              <tr>
                <td
                  className={clsx(
                    styles.ambassador_info__td,
                    styles.ambassador_info__td__total
                  )}
                  colSpan={2}
                >
                  Total
                </td>
                <td
                  className={clsx(
                    styles.ambassador_info__td,
                    styles.ambassador_info__td__total
                  )}
                >
                  {totalReferrals}
                </td>
                <td
                  className={clsx(
                    styles.ambassador_info__td,
                    styles.ambassador_info__td__total
                  )}
                >
                  {totalRegistrations}
                </td>
                <td
                  className={clsx(
                    styles.ambassador_info__td,
                    styles.ambassador_info__td__total
                  )}
                />
                <td
                  className={clsx(
                    styles.ambassador_info__td,
                    styles.ambassador_info__td__total
                  )}
                />
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.page__footer}>
          <SecondaryButton
            className={styles.button__back}
            label="Back"
            onClick={() => navigate({ pathname: PATH.CUSTOMERS, state })}
          />

          <PrimaryButton
            className={styles.button__save}
            label="Save"
            onClick={formik.handleSubmit}
          />
        </div>
      </div>
    </>
  )
}

export default AmbassadorEdit
