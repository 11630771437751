import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import styles from './LaunchCampaign.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  AddAmbassadorManuallyModal,
  PencilIcon,
  TrashIcon,
  ConfirmModal,
  CampSetupListModal,
  SendTestEmailsModal,
  ChevronArrowIcon,
  TextInput,
  HelpModal,
} from 'components'
import { useStateValue } from 'state'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { getStorageItem } from 'utils'
import { CAMPAIGN_TYPE, PATH, PERSON_TYPE, STORAGE_NAME } from 'utils/constants'
import { useNavigate } from 'hooks'
import DataTable from 'react-data-table-component'
import SampleCsv from 'assets/customers.csv'

const filters = [
  { key: 'ALL', value: 'All' },
  { key: 'UNINVITED', value: 'Unsent' },
  { key: 'INVITED', value: 'Invited' },
  { key: 'JOINED', value: 'Joined' },
]
const LaunchCampaign = ({
  isLocked = false,
  step: initStep,
  onBack = () => null,
  //onNext = () => null,
  data: initData = {},
  emailData,
  campaignId = null,
  campaignType = null,
  status = null,
  testMode = null,
  startDate,
  endDate,
  campaignSlug = '',
}) => {
  const publicLink = `${window.location.origin}/a/c/${campaignSlug}`
  const campIdInUse = getStorageItem(STORAGE_NAME.CAMP_IN_USE)
  const csvUploaderRef = useRef(null)
  const downloadSampleRef = useRef(null)

  const refItems = {
    filterMenu: useRef(null),
  }
  const {
    action: {
      sendTestAmbassadorInvitations,
      uploadCampaignCsvFile,
      getCampCsvFiles,
      uploadCampaignSourceFiles,
      getCampaignAmbassadors,
      deleteCampaignAmbassadors,
      addCampaignAmbassador,
      updateCampaignAmbassador,
      launchCampaign,
    },
    state: { help },
  } = useStateValue()
  const [showAddAmbassadorManually, setShowAddAmbassadorManually] =
    useState(false)
  const [currentAmbassador, setCurrentAmbassador] = useState({})
  const [ambassadors, setAmbassadors] = useState([])
  const [ambassadorPersonIds, setAmbassadorPersonIds] = useState([])
  const [isSubmitted, setSubmitted] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showConfirmSingleDeleteModal, setShowConfirmSingleDeleteModal] =
    useState(false)
  const [showConfirmLaunchModal, setShowConfirmLaunchModal] = useState(false)
  const [showConfirmTestModal, setShowConfirmTestModal] = useState(false)

  const [showCampSetupListModal, setShowCampSetupListModal] = useState(false)
  const [showSendTestEmailsModal, setShowSendTestEmailsModal] = useState(false)
  const [idsToConfirm, setIdsToConfirm] = useState([])
  const [campCsvFiles, setCampCsvFiles] = useState([])
  const [isSendingInvitations, setSendingInvitations] = useState(false)
  const [fileName, setFileName] = useState(null)

  const [isNewMenuOpened, setNewMenuOpened] = useState(false)
  const [filterName, setFilterName] = useState(null)
  const [filterText, setFilterText] = React.useState('')

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      testEmail_1: '',
      testEmail_2: '',
      testEmail_3: '',
      ...initData,
    },
    validationSchema: Yup.object({
      testEmail_1: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email.'),
      testEmail_2: Yup.string().email('Please enter a valid email.').nullable(),
      testEmail_3: Yup.string().email('Please enter a valid email.').nullable(),
    }),
    onSubmit: (args) => {
      setShowSendTestEmailsModal(true)
    },
  })

  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [initStep])

  // eslint-disable-next-line
  useEffect(
    () =>
      campaignId ? getCampaignAmbassadors(campaignId, setAmbassadors) : null,
    // eslint-disable-next-line
    [campaignId]
  )

  useEffect(() => {
    if (!isSubmitted) return

    if (_.isEmpty(formik.touched) || _.isEmpty(formik.errors)) return

    const toastErrors = (errors) =>
      _.values(errors).forEach((error) => {
        if (typeof error === 'string') toast.error(error)
        else toastErrors(error)
      })

    toastErrors(formik.errors)

    setSubmitted(false)
    // eslint-disable-next-line
  }, [isSubmitted])

  const handleEditAmbassador = (ambassador) => {
    setCurrentAmbassador(ambassador)
    setShowAddAmbassadorManually(true)
  }

  const handleClickAddAmbassadorManually = () => {
    setCurrentAmbassador({})
    setShowAddAmbassadorManually(true)
  }

  const handleAddAmbassadorManually = (data) => {
    if (data?.id)
      updateCampaignAmbassador(
        campaignId,
        {
          ...currentAmbassador,
          Person: { ...currentAmbassador.Person, ...data },
        },
        (ambassador) => {
          setAmbassadors((ambassadors) =>
            ambassadors.map((_ambassador) =>
              _ambassador.personId === ambassador.personId
                ? ambassador
                : _ambassador
            )
          )
        }
      )
    else
      addCampaignAmbassador(campaignId, data, (ambassador) => {
        if (ambassador) {
          toast.info(`Ambassador Added`)
          setAmbassadors((ambassadors) =>
            _.concat(ambassadors, {
              ...ambassador,
              Person: { id: ambassador.personId, ...data },
            })
          )
        } else {
          toast.info(`Ambassador Already Exists`)
        }
      })
  }

  const handleSendTestEmails = (data) =>
    !isLocked &&
    sendTestAmbassadorInvitations({
      campaignId,
      ...emailData,
      emails: Object.values(data).filter((email) => !!email),
    })

  const handleAddCsvFile = (e) => {
    setFileName(e.target.files[0].name)
    uploadCampaignCsvFile(
      campaignId,
      e.target.files[0],
      campaignType === CAMPAIGN_TYPE.COUNSELOR
        ? PERSON_TYPE.COUNSELOR
        : PERSON_TYPE.CUSTOMER,
      (newAmbassadors) => {
        newAmbassadors.forEach((newAmbassador) => {
          let origAmbassador = ambassadors.find((original) => {
            return newAmbassador['Person'].email === original['Person'].email
          })
          origAmbassador
            ? Object.assign(origAmbassador, newAmbassador)
            : ambassadors.push(newAmbassador)
        })
        setAmbassadors(ambassadors)
        e.target.value = null
      }
    )
  }

  // const handleToggleAmbassador = (personId) => {
  //   if (ambassadorPersonIds.includes(personId))
  //     setAmbassadorPersonIds(
  //       ambassadorPersonIds.filter((pId) => pId !== personId)
  //     )
  //   else setAmbassadorPersonIds(_.concat(ambassadorPersonIds, personId))
  // }

  const handleConfirmOK = () => {
    if (!idsToConfirm.length) return

    deleteCampaignAmbassadors(campaignId, idsToConfirm, () => {
      setAmbassadors((ambassadors) =>
        ambassadors.filter(
          (ambassador) => !idsToConfirm.includes(ambassador.personId)
        )
      )
      setAmbassadorPersonIds(
        ambassadorPersonIds.filter((id) => !idsToConfirm.includes(id))
      )
      setShowConfirmModal(false)
      setShowConfirmSingleDeleteModal(false)
    })
  }

  const handleConfirmCancel = () => {
    setShowConfirmModal(false)
    setShowConfirmSingleDeleteModal(false)
    setIdsToConfirm([])
  }

  const handleConfirmLaunchOK = () => {
    setSendingInvitations(true)

    launchCampaign(
      campaignId,
      ambassadorPersonIds.length ? { peopleIds: ambassadorPersonIds } : {},
      () => {
        getCampaignAmbassadors(campaignId, (ambassadors) => {
          setAmbassadors(ambassadors)
          setShowConfirmLaunchModal(false)
          setSendingInvitations(false)
          toast.success('Campaign is launched successfully.')
          navigate({ pathname: PATH.CAMPAIGNS })
        })
      }
    )
  }

  const handleConfirmTestOK = () => {
    setSendingInvitations(true)

    launchCampaign(
      campaignId,
      ambassadorPersonIds.length
        ? { peopleIds: ambassadorPersonIds, testMode: true }
        : { testMode: true },
      () => {
        getCampaignAmbassadors(campaignId, (ambassadors) => {
          setAmbassadors(ambassadors)
          setShowConfirmLaunchModal(false)
          setSendingInvitations(false)
          toast.success('Campaign is Launched in Test Mode')
          navigate({ pathname: PATH.CAMPAIGNS })
        })
      }
    )
  }

  const handleConfirmLaunchCancel = () => {
    setShowConfirmLaunchModal(false)
  }

  const handleClickCampSetupList = () =>
    getCampCsvFiles(campIdInUse, (csvFiles) => {
      setCampCsvFiles(csvFiles)
      setShowCampSetupListModal(true)
    })

  const handleImportSetupList = (selectedFiles) =>
    uploadCampaignSourceFiles(campaignId, selectedFiles, () =>
      getCampaignAmbassadors(campaignId, setAmbassadors)
    )

  const handleDeleteAmbassador = (personId) => {
    setIdsToConfirm([personId])
    setShowConfirmSingleDeleteModal(true)
  }

  const handleDeleteAllAmbassadors = () => {
    if (ambassadorPersonIds.length) {
      setIdsToConfirm(ambassadorPersonIds)
      setShowConfirmModal(true)
    } else toast.error('Select ambassadors to delete.')
  }

  const saveAndClose = () => {
    // There's really nothing to save.
    navigate({ pathname: PATH.CAMPAIGNS })
  }

  const handleClickCopyPublicLink = () => {
    navigator.clipboard.writeText(publicLink)
    toast.info(`Public Link is copied.`)
  }

  const columns = [
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Name
        </div>
      ),
      selector: (ambassador) =>
        ambassador.Person.firstName + ' ' + ambassador.Person.lastName,
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Sends
        </div>
      ),
      selector: (ambassador) =>
        ambassador?.stats?.length > 0
          ? ambassador.stats.filter((stat) => stat.eventType === 'delivered')
              .length
          : '-',
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__status
          )}
        >
          Join Status
        </div>
      ),
      selector: (ambassador) =>
        ambassador.status === 'UNINVITED'
          ? 'Unsent'
          : ambassador.status[0].toUpperCase() +
            ambassador.status.substring(1).toLowerCase(),
      sortable: true,
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__name
          )}
        >
          Email Status
        </div>
      ),
      selector: (ambassador) => (
        <span
          className={clsx(
            styles.ambassador_list__th__name,
            styles[`ambassador__status--${ambassador.status}`]
          )}
        >
          {ambassador?.stats?.length > 0
            ? ambassador?.stats[0]?.eventType
            : '-'}
          <span style={{ color: '#999999' }}>
            {ambassador?.stats?.length > 0
              ? ` ( ${new Date(
                  ambassador?.stats[0]?.created_at
                ).toLocaleDateString()} )`
              : null}
          </span>
        </span>
      ),
    },
    {
      name: (
        <div
          className={clsx(
            styles.ambassador_list__th,
            styles.ambassador_list__th__actions
          )}
        >
          Actions
        </div>
      ),
      selector: (ambassador) => (
        <div
          className={clsx(
            styles.ambassador_list__td,
            styles.ambassador_list__td__actions
          )}
        >
          <button
            disabled={isLocked}
            onClick={() => handleEditAmbassador(ambassador)}
          >
            <PencilIcon
              className={clsx(isLocked && styles['button__icon--disabled'])}
            />
          </button>

          <button
            disabled={isLocked}
            onClick={() => handleDeleteAmbassador(ambassador.personId)}
          >
            <TrashIcon
              className={clsx(isLocked && styles['button__icon--disabled'])}
            />
          </button>
        </div>
      ),
    },
  ]

  const filteredAmbassadors = ambassadors.filter(
    (ambassador) =>
      ambassador.fullName &&
      ambassador.fullName.toLowerCase().includes(filterText.toLowerCase())
  )

  const checkLaunch = () => {
    if (moment(endDate) < moment())
      toast.error('Campaign dates are invalid. Please review campaign dates.')
    else
      ambassadors.length > 0
        ? setShowConfirmLaunchModal(true)
        : handleConfirmLaunchOK()
  }
  return (
    <>
      <div className={styles.section}>
        <div className={styles.profile_container}>
          <h4 className={styles.section__title}>
            Launch Campaign <br />
          </h4>
          {help.find((h) => h.tag === 'launch_campaign_overview') && (
            <HelpModal
              className={styles.helpModal}
              help={help.find((h) => h.tag === 'launch_campaign_overview')}
            >
              <p>
                {help.find((h) => h.tag === 'launch_campaign_overview')?.text}
              </p>
            </HelpModal>
          )}
        </div>
        {status === 'ACTIVE' && !testMode ? (
          <div style={{ display: 'flex' }}>
            <p className={styles.section__description}>
              <div>Start Date:</div>{' '}
              {new Date(startDate).toLocaleDateString('en-US')}
            </p>
            <p className={styles.section__description}>
              <div>End Date:</div>{' '}
              {new Date(endDate).toLocaleDateString('en-US')}
            </p>
          </div>
        ) : null}
        <div className={styles.form__wrapper}>
          <div className={styles.form__header}>
            <div className={styles.form__header__center}>
              <h6 className={styles.form__header__title}>
                TESTING AND LAUNCHING THE CAMPAIGN
              </h6>
              {help.find(
                (h) => h.tag === 'launch_campaign_test_and_launch'
              ) && (
                <HelpModal
                  className={styles.helpModal__types}
                  help={help.find(
                    (h) => h.tag === 'launch_campaign_test_and_launch'
                  )}
                >
                  <p>
                    {
                      help.find(
                        (h) => h.tag === 'launch_campaign_test_and_launch'
                      )?.text
                    }
                  </p>
                </HelpModal>
              )}
            </div>
            <div className={styles.form__header__content}>
              <h6 className={styles.form__header__content__title}>
                1. TEST CAMPAIGN:
              </h6>
              <p className={styles.profile_container}>
                Use the ADD MANUALLY button to add people to test your campaign
                and click the TEST CAMPAIGN button to review, edit and finalize
                your campaign.
                {help.find(
                  (h) => h.tag === 'launch_campaign_test_campaign'
                ) && (
                  <HelpModal
                    className={styles.helpModal__types}
                    help={help.find(
                      (h) => h.tag === 'launch_campaign_test_campaign'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'launch_campaign_test_campaign'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
              </p>
            </div>
            <div className={styles.form__header__content}>
              <h6 className={styles.form__header__content__title}>
                2. LAUNCH CAMPAIGN:
              </h6>
              <p>
                <div className={styles.profile_container}>
                  To clear Test data and activate all links, you must click the
                  LAUNCH CAMPAIGN button.
                  {help.find(
                    (h) => h.tag === 'launch_campaign_launch_campaign'
                  ) && (
                    <HelpModal
                      className={styles.helpModal__types}
                      help={help.find(
                        (h) => h.tag === 'launch_campaign_launch_campaign'
                      )}
                    >
                      <p>
                        {
                          help.find(
                            (h) => h.tag === 'launch_campaign_launch_campaign'
                          )?.text
                        }
                      </p>
                    </HelpModal>
                  )}
                </div>
                <ul>
                  <li>
                    If inviting Ambassadors from Camp Tree, upload Ambassadors
                    and click the LAUNCH CAMPAIGN button. This will send the
                    Initial Invitation Email (Ambassador Setup).
                  </li>
                  <li>
                    If inviting Ambassadors from outside of Camp Tree, click the
                    LAUNCH CAMPAIGN button, then click the{' '}
                    <b>
                      <i>Share Your Public Link</i>
                    </b>{' '}
                    button below to insert in your communications to invite
                    Ambassadors.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <a
            ref={downloadSampleRef}
            href={SampleCsv}
            target="customers.csv"
            download="CampTree-sampleCSV.csv"
            hidden
          ></a>
          <input
            ref={csvUploaderRef}
            type="file"
            accept=".csv"
            hidden
            onChange={handleAddCsvFile}
          />
          <div className={styles.form__header__secondary}>
            <div className={styles.form__green__header__first}>
              <div className={styles.form__green__header__first__title}>
                <h6 className={styles.form__header__title}>
                  Upload & Invite Ambassadors to Join the Campaign
                </h6>
                {help.find(
                  (h) => h.tag === 'launch_campaign_upload_invite_overview'
                ) && (
                  <HelpModal
                    className={styles.helpModal__types}
                    help={help.find(
                      (h) => h.tag === 'launch_campaign_upload_invite_overview'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag === 'launch_campaign_upload_invite_overview'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
              </div>
              <div className={styles.form__green__header__actions}>
                <PrimaryButton
                  className={clsx(styles.button, styles.button__add_manually)}
                  disabled={isLocked}
                  onClick={handleClickAddAmbassadorManually}
                >
                  Add manually
                </PrimaryButton>
                {help.find(
                  (h) =>
                    h.tag === 'launch_campaign_invite_ambassadors_add_manually'
                ) && (
                  <HelpModal
                    className={styles.helpModal__buttons}
                    help={help.find(
                      (h) =>
                        h.tag ===
                        'launch_campaign_invite_ambassadors_add_manually'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag ===
                            'launch_campaign_invite_ambassadors_add_manually'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
                <PrimaryButton
                  className={clsx(styles.button, styles.button__add_manually)}
                  disabled={isLocked}
                  onClick={() => downloadSampleRef.current.click()}
                >
                  Download Sample CSV
                </PrimaryButton>
                {help.find(
                  (h) =>
                    h.tag ===
                    'launch_campaign_invite_ambassadors_download_sample_csv'
                ) && (
                  <HelpModal
                    className={styles.helpModal__buttons}
                    help={help.find(
                      (h) =>
                        h.tag ===
                        'launch_campaign_invite_ambassadors_download_sample_csv'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag ===
                            'launch_campaign_invite_ambassadors_download_sample_csv'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
                <PrimaryButton
                  className={clsx(styles.button, styles.button__add_manually)}
                  disabled={isLocked}
                  onClick={handleClickCampSetupList}
                >
                  View Pre-loaded files
                </PrimaryButton>
                {help.find(
                  (h) =>
                    h.tag ===
                    'launch_campaign_invite_ambassadors_view_pre_loaded_files'
                ) && (
                  <HelpModal
                    className={styles.helpModal__buttons}
                    help={help.find(
                      (h) =>
                        h.tag ===
                        'launch_campaign_invite_ambassadors_view_pre_loaded_files'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag ===
                            'launch_campaign_invite_ambassadors_view_pre_loaded_files'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
                <PrimaryButton
                  className={clsx(styles.button, styles.button__add_manually)}
                  disabled={isLocked}
                  onClick={() => csvUploaderRef.current.click()}
                  description={fileName}
                >
                  UPLOAD CSV DATA
                </PrimaryButton>
                {help.find(
                  (h) =>
                    h.tag ===
                    'launch_campaign_invite_ambassadors_upload_csv_data'
                ) && (
                  <HelpModal
                    className={styles.helpModal__buttons}
                    help={help.find(
                      (h) =>
                        h.tag ===
                        'launch_campaign_invite_ambassadors_upload_csv_data'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) =>
                            h.tag ===
                            'launch_campaign_invite_ambassadors_upload_csv_data'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
              </div>
            </div>

            <div className={styles.form__green__header}>
              <div className={styles.form__green__header__first__title}>
                <h6 className={styles.form__header__title}>
                  Share Your Public Link
                </h6>
                {help.find(
                  (h) => h.tag === 'launch_campaign_share_public_link'
                ) && (
                  <HelpModal
                    className={styles.helpModal__types}
                    help={help.find(
                      (h) => h.tag === 'launch_campaign_share_public_link'
                    )}
                  >
                    <p>
                      {
                        help.find(
                          (h) => h.tag === 'launch_campaign_share_public_link'
                        )?.text
                      }
                    </p>
                  </HelpModal>
                )}
              </div>
              <div className={styles.form__green__header__actions}>
                <PrimaryButton
                  className={clsx(styles.button, styles.button__add_manually)}
                  onClick={handleClickCopyPublicLink}
                >
                  Click to Copy
                </PrimaryButton>
                <p className={styles.form__green__header__actions__desc}>
                  Once LAUNCHED, use this Public Link to invite Ambassadors to
                  join a campaign (email, newsletter, social media, etc.)
                </p>
              </div>
            </div>
          </div>

          <div className={styles.form__main}>
            <div className={styles.ambassador_list__header}>
              <span className={styles.ambassador_list__header__text}>
                {ambassadors.length}
                <span> {filterName ? `${filterName}` : ''}</span>
                <span> Ambassador{ambassadors.length > 1 ? 's' : ''}</span>
              </span>
              <TextInput
                classNames={{
                  container: styles.search__input__wrapper,
                  input: styles.search__input,
                }}
                placeholder="Search by Name"
                value={filterText}
                onChange={setFilterText}
                disabled={!ambassadors.length}
              />
              <TertiaryButton
                className={styles.ambassador_list__button__delete_all}
                label="DELETE"
                onClick={handleDeleteAllAmbassadors}
              />

              <div
                className={(styles.amb_filter__wrapper, styles.filter_button)}
                ref={refItems.filterMenu}
              >
                <TertiaryButton
                  onClick={() => setNewMenuOpened(!isNewMenuOpened)}
                  className={
                    filterName ? styles.amb_filter__filtered_button : ''
                  }
                >
                  <span style={{ color: '#976821' }}>filter</span>
                  {filterName ? (
                    <span
                      className={clsx(styles.amb_filter__icon)}
                      onClick={() => {
                        getCampaignAmbassadors(campaignId, (ambassadors) => {
                          setFilterName(null)
                          setAmbassadors(ambassadors)
                        })
                      }}
                    >
                      x
                    </span>
                  ) : (
                    <ChevronArrowIcon
                      className={clsx(
                        styles.amb_filter__icon,
                        isNewMenuOpened && styles['amb_filter__icon--up']
                      )}
                    />
                  )}
                </TertiaryButton>

                <div
                  className={clsx(
                    styles.amb_filter__menu,
                    isNewMenuOpened && styles['amb_filter__menu--opened']
                  )}
                >
                  {filters.map((filter, index) => (
                    <button
                      key={index}
                      className={
                        filterName === filter.value
                          ? styles.amb_filter__menu__item_active
                          : styles.amb_filter__menu__item
                      }
                      onClick={() => {
                        getCampaignAmbassadors(campaignId, (ambassadors) => {
                          setNewMenuOpened(!isNewMenuOpened)
                          setFilterName(
                            filter.key === 'ALL' ? null : filter.value
                          )
                          setAmbassadors(
                            filter.key === 'ALL'
                              ? ambassadors
                              : ambassadors.filter(
                                  (amb) => amb.status === filter.key
                                )
                          )
                        })
                      }}
                    >
                      {filter.value}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <DataTable
              columns={columns}
              data={filteredAmbassadors}
              pagination
              selectableRows
              onSelectedRowsChange={(e) => {
                setAmbassadorPersonIds(
                  e.selectedRows.map(({ personId }) => personId)
                )
              }}
            />
            {/* <table className={styles.ambassador_list}>
              <thead>
                <tr>
                  <th
                    className={clsx(
                      styles.ambassador_list__th,
                      styles.ambassador_list__th__name
                    )}
                  >
                    Name
                  </th>
                  <th
                    className={clsx(
                      styles.ambassador_list__th,
                      styles.ambassador_list__th__name
                    )}
                  >
                    Sends
                  </th>
                  <th
                    className={clsx(
                      styles.ambassador_list__th,
                      styles.ambassador_list__th__status
                    )}
                  >
                    Join Status
                  </th>
                  <th
                    className={clsx(
                      styles.ambassador_list__th,
                      styles.ambassador_list__th__name
                    )}
                  >
                    Email Status
                  </th>
                  <th
                    className={clsx(
                      styles.ambassador_list__th,
                      styles.ambassador_list__th__actions
                    )}
                  >
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {ambassadors.map((ambassador) => (
                  <tr key={ambassador.Person.email}>
                    <td
                      className={clsx(
                        styles.ambassador_list__td,
                        styles.ambassador_list__td__name
                      )}
                    >
                      <Checkbox
                        disabled={isLocked}
                        label={`${ambassador.Person.firstName} ${ambassador.Person.lastName}`}
                        isChecked={ambassadorPersonIds.includes(
                          ambassador.personId
                        )}
                        onChange={() =>
                          handleToggleAmbassador(ambassador.personId)
                        }
                      />
                    </td>
                    <td
                      className={clsx(
                        styles.ambassador_list__td,
                        styles.ambassador_list__td__status
                      )}
                    >
                      <span
                        className={clsx(
                          styles.ambassador__status,
                          styles[`ambassador__status--${ambassador.status}`]
                        )}
                      >
                        {ambassador?.stats?.length > 0
                          ? ambassador.stats.filter(
                              (stat) => stat.eventType === 'delivered'
                            ).length
                          : '-'}
                      </span>
                    </td>
                    <td
                      className={clsx(
                        styles.ambassador_list__td,
                        styles.ambassador_list__td__status
                      )}
                    >
                      <span
                        className={clsx(
                          styles.ambassador__status,
                          styles[`ambassador__status--${ambassador.status}`]
                        )}
                      >
                        {ambassador.status.toLowerCase()}
                      </span>
                    </td>
                    <td
                      className={clsx(
                        styles.ambassador_list__td,
                        styles.ambassador_list__td__status_date
                      )}
                    >
                      <span
                        className={clsx(
                          styles.ambassador_list__th__name,
                          styles[`ambassador__status--${ambassador.status}`]
                        )}
                      >
                        {ambassador?.stats?.length > 0
                          ? ambassador?.stats[0]?.eventType
                          : '-'}
                        <span style={{ color: '#999999' }}>
                          {ambassador?.stats?.length > 0
                            ? ` ( ${new Date(
                                ambassador?.stats[0]?.created_at
                              ).toLocaleDateString()} )`
                            : null}
                        </span>
                      </span>
                    </td>

                    <td
                      className={clsx(
                        styles.ambassador_list__td,
                        styles.ambassador_list__td__actions
                      )}
                    >
                      <button
                        disabled={isLocked}
                        onClick={() => handleEditAmbassador(ambassador)}
                      >
                        <PencilIcon
                          className={clsx(
                            isLocked && styles['button__icon--disabled']
                          )}
                        />
                      </button>

                      <button
                        disabled={isLocked}
                        onClick={() =>
                          handleDeleteAmbassador(ambassador.personId)
                        }
                      >
                        <TrashIcon
                          className={clsx(
                            isLocked && styles['button__icon--disabled']
                          )}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </div>
        </div>

        <div className={styles.section__footer}>
          <SecondaryButton
            className={styles.button__back}
            label="Back"
            onClick={onBack}
          />
          <SecondaryButton
            className={styles.button__back}
            label="Save & Close"
            onClick={() => saveAndClose(false)}
          />
          {status === 'INCOMPLETE' || (status === 'ACTIVE' && testMode) ? (
            <PrimaryButton
              className={styles.button__launch}
              label="Test Campaign"
              disabled={isLocked}
              onClick={() => {
                if (moment(endDate) < moment())
                  toast.error(
                    'Campaign dates are invalid. Please review campaign dates.'
                  )
                else
                  ambassadors.length > 0
                    ? setShowConfirmTestModal(true)
                    : toast.error(
                        'Add alteast one ambassador to launch Test Campaign'
                      )
              }}
            />
          ) : null}

          <PrimaryButton
            className={styles.button__launch}
            label={
              status === 'ACTIVE' && !testMode
                ? 'Send Invitations'
                : 'Launch Campaign'
            }
            disabled={isLocked}
            onClick={checkLaunch}
          />
        </div>
      </div>

      <AddAmbassadorManuallyModal
        classNames={{ modal: styles.modal__add_ambassador_manually }}
        data={currentAmbassador.Person}
        show={showAddAmbassadorManually}
        onClose={() => setShowAddAmbassadorManually(false)}
        onAdd={handleAddAmbassadorManually}
      />

      <ConfirmModal
        show={showConfirmSingleDeleteModal}
        title="Confirm Delete"
        description="Delete this ambassador?"
        onCancel={handleConfirmCancel}
        onOK={handleConfirmOK}
      />

      <ConfirmModal
        show={showConfirmModal}
        title="Confirm Delete"
        description={
          ambassadorPersonIds.length
            ? 'Delete selected ambassadors?'
            : 'Delete all ambassadors?'
        }
        onCancel={handleConfirmCancel}
        onOK={handleConfirmOK}
      />

      <ConfirmModal
        show={showConfirmLaunchModal}
        title={testMode ? 'Launch Campaign' : 'Sending Invitation Emails'}
        description={
          testMode
            ? `Test Data will be cleared and brand new campaign will be launched. This will send invitations to ${
                ambassadorPersonIds.length ? 'selected' : 'all'
              } ambassadors.`
            : isSendingInvitations
            ? 'Sending...'
            : `This will send invitations to ${
                ambassadorPersonIds.length ? 'selected' : 'all'
              } ambassadors.`
        }
        isLoading={isSendingInvitations}
        onCancel={handleConfirmLaunchCancel}
        onOK={handleConfirmLaunchOK}
      />

      <ConfirmModal
        show={showConfirmTestModal}
        title="Test Mode"
        description={
          isSendingInvitations
            ? 'Sending...'
            : `Campaign will be sent in Test Mode. Data will be cleared out when campaign is Launched. This will send invitations to ${
                ambassadorPersonIds.length ? 'selected' : 'all'
              } ambassadors.`
        }
        isLoading={isSendingInvitations}
        onCancel={() => setShowConfirmTestModal(false)}
        onOK={handleConfirmTestOK}
      />

      <SendTestEmailsModal
        classNames={{ modal: styles.modal__add_ambassador_manually }}
        data={formik.values}
        show={showSendTestEmailsModal}
        onClose={() => setShowSendTestEmailsModal(false)}
        onSend={handleSendTestEmails}
      />

      <CampSetupListModal
        show={showCampSetupListModal}
        csvFiles={campCsvFiles}
        onClose={() => setShowCampSetupListModal(false)}
        onImport={handleImportSetupList}
      />
    </>
  )
}

export default LaunchCampaign
