export const AUTH_STATE = {
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
}

export const ACTION = {
  START: 'START',
  END: 'END',
  ERROR: 'ERROR',

  AUTH__SIGN_IN: 'AUTH__SIGN_IN',
  AUTH__SIGN_OUT: 'AUTH__SIGN_OUT',
  AUTH__UPDATE_PROFILE: 'AUTH__UPDATE_PROFILE',

  CAMP__SET_ACTIVE: 'CAMP__SET_ACTIVE',
  CAMP__GET_ACTIVE: 'CAMP__GET_ACTIVE',

  CAMP__GET_ALL: 'CAMP__GET_ALL',
  CAMP__ADD: 'CAMP__ADD',
  CAMP__UPDATE: 'CAMP__UPDATE',
  CAMP__DELETE: 'CAMP__DELETE',

  CAMPAIGN__GET_ALL: 'CAMPAIGN__GET_ALL',
  CAMPAIGN__ADD: 'CAMPAIGN__ADD',
  CAMPAIGN__UPDATE: 'CAMPAIGN__UPDATE',
  CAMPAIGN__DELETE: 'CAMPAIGN__DELETE',
  CAMPAIGN__CLOSE: 'CAMPAIGN__CLOSE',
  HELP_TAGS: 'HELP_TAGS',
}

export const ERR_CODE = {
  UNKNOWN: 'UNKNOWN',
  AUTH_UNKNOWN: 'AUTH_UNKNOWN',
  AUTH_NOT_FOUND: 'AUTH_NOT_FOUND',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_NAME: 'AUTH_NAME',
  AUTH_EMAIL: 'AUTH_EMAIL',
  AUTH_PASSWORD: 'AUTH_PASSWORD',
  AUTH_NEW_PASSWORD: 'AUTH_NEW_PASSWORD',
  AUTH_PHONE: 'AUTH_PHONE',
  CAMP_NOT_FOUND: 'CAMP_NOT_FOUND',
  CAMP_UNKNOWN: 'CAMP_UNKNOWN',
  CAMPAIGN_NOT_FOUND: 'CAMPAIGN_NOT_FOUND',
  CAMPAIGN_UNKNOWN: 'CAMPAIGN_UNKNOWN',
  PUBLIC_UNKNOWN: 'PUBLIC_UNKNOWN',
  AMBASSADOR_NOT_FOUND: 'AMBASSADOR_NOT_FOUND',
  POPUP_BLOCKED: 'POPUP_BLOCKED',
  NONE: 'NONE',
}

export const STORAGE_NAME = {
  AUTH_TOKEN: 'camptree-auth-token',
  AUTH_STATE: 'camptree-auth-state',
  AUTH_REMEMBER_ME: 'camptree-auth-remember-me',
  PREVIEW_AS_LANDING: 'camptree-preview-ambassador_setup-landing',
  PREVIEW_AS_EMAIL: 'camptree-preview-ambassador_setup-email',
  PREVIEW_AS_DASHBOARD: 'camptree-preview-ambassador_setup-dashboard',
  PREVIEW_AS_TERMS: 'camptree-preview-ambassador_setup-terms',
  PREVIEW_PS: 'camptree-preview-prospect',
  PREVIEW_PS_LANDING: 'camptree-preview-prospect_setup-landing',
  PREVIEW_PS_LEARN_MORE: 'camptree-preview-prospect_setup-learn_more',
  CAMP_IN_USE: 'camptree-camp-id-in-use',
}

export const PATH = {
  HOME: '/',
  DASHBOARD: '/dashboard',

  CAMPS: '/camps',
  CAMPS__ADD: '/camps/add',
  CAMPS__EDIT: '/camps/edit',
  CAMPS__STATS: '/camps/stats',

  CAMPAIGNS: '/campaigns',
  CAMPAIGNS__EDIT: '/campaigns/edit',

  PREVIEW__AS__LANDING: '/preview/ambassador-setup/l',
  PREVIEW__AS__EMAIL: '/preview/ambassador-setup/email',
  PREVIEW__AS__DASHBOARD: '/preview/ambassador-setup/d',

  PREVIEW__PS: '/preview/prospect-setup',
  PREVIEW__PS__EMAIL: '/preview/prospect-setup/email',

  AMBASSADORS: '/ambassadors',
  AMBASSADORS__EDIT: '/ambassadors/edit',

  PROSPECTS: '/prospects',
  PROSPECTS__EDIT: '/prospects/edit',

  NOTIFICATIONS: '/notifications',

  AUTH__SIGN_IN: '/sign-in',
  AUTH__SIGN_UP: '/sign-up',
  AUTH__VERIFY: '/verify',
  AUTH__SIGN_UP__SUCCESS: '/sign-up/success',
  AUTH__FORGOT__PASSWORD: '/forgot-password',
  AUTH__FORGOT__PASSWORD__SUCCESS: '/forgot-password/success',
  AUTH__YOUR_PROFILE: '/your-profile',
  AUTH__RESET__PASSWORD: '/reset-password',
  AUTH__RESET__PASSWORD_SUCCESS: '/reset-password',

  PUBLIC__AS: '/a',
  PUBLIC__AMBASSADOR_DASHBOARD: '/a/d',
  PUBLIC__CE: '/ce',
  PUBLIC__PS: '/p',

  NOT_FOUND: '/404',
  HELP: '/help',
}

export const CAMP_INVITATION_STATUS = {
  INVITED: 'INVITED',
  COMPLETE: 'COMPLETE',
}

export const CAMP_STATUS = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
}

export const CAMP_SEASON = {
  SPRING: 'Spring',
  SUMMER: 'Summer',
  AUTUMN: 'Autumn',
  WINTER: 'Winter',
}

export const CAMP_ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
}

export const CAMPAIGN_STATUS = {
  ACTIVE: 'ACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  CLOSED: 'CLOSED',
  DRAFT: 'DRAFT',
  TEST: 'TEST',
  ARCHIVED: 'ARCHIVED',
}

export const CAMPAIGN_STEP = {
  PERFORMANCE: 'PERFORMANCE',
  VERIFICATION: 'VERIFICIATION',
  DEFINE: 'DEFINE',
  DEFINE__CAMPAIGN: 'DEFINE__CAMPAIGN',
  DEFINE__AMBASSADOR: 'DEFINE__AMBASSADOR',
  DEFINE__PROSPECT: 'DEFINE__PROSPECT',
  AMBASSADOR_SETUP: 'AMBASSADOR_SETUP',
  AMBASSADOR_SETUP__INVITATIONS: 'AMBASSADOR_SETUP__INVITATIONS',
  AMBASSADOR_SETUP__DASHBOARD: 'AMBASSADOR_SETUP__DASHBOARD',
  AMBASSADOR_SETUP__EMAILS: 'AMBASSADOR_SETUP__EMAILS',
  PROSPECT_SETUP: 'PROSPECT_SETUP',
  PROSPECT_SETUP__INVITATIONS: 'PROSPECT_SETUP__INVITATIONS',
  PROSPECT_SETUP__EMAILS: 'PROSPECT_SETUP__EMAILS',
  LAUNCH: 'LAUNCH',
  REVIEW: 'REVIEW',
  REVIEW__STEP_1: 'REVIEW__STEP_1',
  REVIEW__STEP_2: 'REVIEW__STEP_2',
  REVIEW__STEP_3: 'REVIEW__STEP_3',
}

export const REWARD_TYPE = {
  AMBASSADOR: 'AMBASSADOR',
  PROSPECT: 'PROSPECT',
}

export const REWARD__AR_TYPE = {
  GIFT: 'GIFT',
  MONEY_DOLLAR: 'MONEY',
  MONEY_PERCENTAGE: 'PERCENT',
}

export const REWARD__PR_TYPE = {
  GIFT: 'GIFT',
  MONEY_DOLLAR: 'MONEY',
  MONEY_PERCENTAGE: 'PERCENT',
}

export const MESSAGE = {
  A__INVITATION: {
    LABEL: 'Ambassador Invitation',
    TYPE: 'ambassador-invitation',
  },
  A__EMAIL: {
    LABEL: 'Confirm Ambassador Email',
    TYPE: 'ambassador-email-confirmation',
    HELP: 'ambassador_setup_emails_confirm_ambassador',
  },
  A__ENROLL: {
    LABEL: 'Confirm Enroll / Unique Link',
    TYPE: 'ambassador-enrollment-confirmation',
    HELP: 'ambassador_setup_emails_confirm_enroll',
  },
  A__REGISTRATION: {
    LABEL: 'Confirm Registration',
    TYPE: 'ambassador-registration-confirmation',
    HELP: 'ambassador_setup_emails_confirm_registration',
  },
  A__REFERRAL: {
    LABEL: 'Confirm Promo Code Claimed',
    TYPE: 'ambassador-referral-thanks',
    HELP: 'ambassador_setup_emails_confirm_promo',
  },
  P__CONFIRM: {
    LABEL: 'Promo Code Claimed Email',
    TYPE: 'prospect-promo-code',
    HELP: 'prospect_setup_emails_claim_code',
  },
  P__HELP: {
    LABEL: 'Inquiry (Learn More) Email',
    TYPE: 'prospect-how-can-we-help',
    HELP: 'prospect_setup_emails_learn_more',
  },
  P__PROMO_CODE: {
    LABEL: 'Promo Code Reminder to enroll',
    TYPE: 'prospect-promo-code',
  },
  P__REGISTRATION: {
    LABEL: 'Confirm Registration (verification)',
    TYPE: 'prospect-registration-confirmation',
  },
}

export const PERSON_TYPE = {
  CUSTOMER: 'CUSTOMER',
  AMBASSADOR: 'AMBASSADOR',
  COUNSELOR: 'COUNSELOR',
}

export const AMBASSADOR_STATUS = {
  UNINVITED: 'UNSENT',
  INVITED: 'INVITED',
  JOINED: 'JOINED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  REMINDED: 'REMINDED',
}
export const REFERRAL_TYPE = {
  REFERRAL: 'REFERRAL',
  REFERRAL_REJECTED: 'REFERRAL_REJECTED',
  INQUIRY: 'INQUIRY',
  INQUIRY_REJECTED: 'INQUIRY_REJECTED',
  REGISTRATION: 'REGISTRATION',
  REGISTRATION_REJECTED: 'REGISTRATION_REJECTED',
}

export const REWARD__AR__FOR_OPTIONS = ['Registration', 'Referral']

export const REWARD__AR__USE_OPTIONS = ['Single', 'Multiple']

export const REWARD__AR__CURRENCY_OPTIONS = ['$', '€', '£']

export const REWARD__AR__ITEM = {
  type: REWARD__AR_TYPE.MONEY_DOLLAR,
  imgURL: null,
  // for: REWARD__AR__FOR_OPTIONS[0],
  for: 'Registration',
  desc: '',
  giftDesc: '',
  use: REWARD__AR__USE_OPTIONS[1],
  amount: '',
  promocode: '',
}

export const REWARD__PR__USE_OPTIONS = ['Single', 'Multiple']

export const REWARD__PR__ITEM = {
  type: REWARD__PR_TYPE.MONEY_DOLLAR,
  desc: '',
  giftDesc: '',
  use: REWARD__PR__USE_OPTIONS[1],
  amount: '',
  promocode: '',
}

export const CAMPAIGN_TYPE = {
  REGISTRATION: 'CAMPER',
  COUNSELOR: 'COUNSELOR',
  CUSTOM: 'CUSTOM',
}

export const PERFORMANCE_METRICS_ORDER = [
  'ambassador-invitation',
  'ambassador-email-confirmation',
  'ambassador-enrollment-confirmation',
  'ambassador-referral-thanks',
  'ambassador-registration-confirmation',
  'prospect-how-can-we-help',
  'prospect-promo-code',
]
export const PERFORMANCE_METRICS = {
  'ambassador-invitation': 'Invite Ambassadors via Camp Tree',
  'ambassador-email-confirmation': 'Confirm Ambassador Email',
  'ambassador-enrollment-confirmation': 'Confirm Enroll/Unique Link',
  'ambassador-referral-thanks': 'Confirm Promo Code Claimed',
  'ambassador-registration-confirmation': 'Confirm Registration',
  'prospect-how-can-we-help': 'Inquiry (Learn More) Email',
  'prospect-promo-code': 'Promo Code Claimed Email',
}

export const REPORT_TYPE = {
  AMBASSADOR: 'AMBASSADOR',
  RELATIONSHIP: 'AMBASSADOR/PROSPECT',
  VERIFICATION: 'ALL DATA',
}
