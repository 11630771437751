import { AccordionSection, PrimaryButton, TextInput } from 'components'
import React, { useEffect, useState } from 'react'
import styles from './Subscriptions.module.scss'
import clsx from 'clsx'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useStateValue } from 'state'

const Subscriptions = ({
  monthly_price = 10,
  yearly_price = 10,
  description,
  note,
  submitButtonText = 'Continue to payment',
  onOK = () => null,
  includes = [],
  terms = [],
  purpose,
}) => {
  const {
    action: { getPromoCodes },
  } = useStateValue()

  const [sectionOpened, setSectionOpened] = useState(null)
  const [promoCode, setPromoCode] = useState('')
  const [availablePromos, setAvailablePromos] = useState([])
  const [plan, setPlan] = useState('')

  useEffect(() => {
    if (purpose) getPromoCodes(purpose, setAvailablePromos)
  }, [purpose])

  const formik = useFormik({
    initialValues: { promo: '' },
    enableReinitialize: true,
    validationSchema: Yup.object({
      promo: Yup.string().oneOf(availablePromos, 'Invalid Coupon'),
      plan: Yup.string().oneOf(['monthly', 'yearly'], 'Invalid Plan'),
    }),
    onSubmit: (formValues, error) => {
      if (formValues.promo) {
        toast.success('Coupon Added Successfully')
        setSectionOpened(false)
      }
    },
  })

  return (
    <>
      <section className={styles.products}>
        <div
          className={clsx(
            styles.field__type,
            plan === 'monthly' && styles.field__type__active
          )}
          onClick={(e) => {
            e.preventDefault()
            setPlan('monthly')
          }}
        >
          <div className={clsx(styles.field__type__details)}>
            <div>
              <p
                className={styles.subscription__name}
                data-cy="payMonthlyLabel"
              >
                Pay Monthly
              </p>
              <h3 className={styles.subscription__price}>${monthly_price}</h3>
              {description && (
                <p className={styles.subscription__description}>
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            styles.field__type,
            plan === 'yearly' && styles.field__type__active
          )}
          onClick={(e) => {
            e.preventDefault()
            setPlan('yearly')
          }}
        >
          <div className={clsx(styles.field__type__details)}>
            <div>
              <p className={styles.subscription__name} data-cy="payYearlyLabel">
                Pay Yearly
              </p>

              <h3 className={styles.subscription__price}>${yearly_price}</h3>
              {description && (
                <p className={styles.subscription__description}>
                  {description}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      {includes.length > 0 ? (
        <div className={styles.plan_detail}>
          Includes:
          {includes.map((item, key) => (
            <span key={key}>
              <br /> {item}
            </span>
          ))}
        </div>
      ) : null}
      <section className={styles.promo}>
        <AccordionSection
          className={styles.accordion_section}
          description={
            promoCode ? 'Promo Code: ' + promoCode : 'Do you have a promo code?'
          }
          // description={promoSuccess ? promo : 'Do you have a promo code?'}
          opened={sectionOpened === 'promo-code'}
          onActionToOpen={() => setSectionOpened('promo-code')}
          onActionToClose={() => setSectionOpened(null)}
          dataCy="promoAccordion"
        >
          <div className={styles.promo__input}>
            <TextInput
              placeholder="Promo Code"
              value={formik.values.promo}
              onChange={(val) => {
                setPromoCode(val)
                formik.setFieldValue('promo', val)
              }}
              {...(formik.errors.promo
                ? { isError: true, captionText: formik.errors.promo }
                : {})}
              onSubmit={formik.handleSubmit}
            />
          </div>
        </AccordionSection>
      </section>

      <PrimaryButton
        className={styles.submit_btn}
        onClick={() =>
          onOK(plan, availablePromos.indexOf(promoCode) > -1 ? promoCode : null)
        }
      >
        {submitButtonText}
      </PrimaryButton>

      {note && <p className={styles.subscription__note}>{note}</p>}

      {terms.length > 0 ? (
        <ul className={styles.term_detail}>
          {terms.map((item, key) => (
            <li key={key}>{item}</li>
          ))}
        </ul>
      ) : null}
    </>
  )
}

export default Subscriptions
